import { convertRemToPixels } from '../../../lib/utils';
import { PuckSize, GanttScale } from '../../../lib/constants';

const getRightPuckSize = (ganttRowHeight) => {
  if (ganttRowHeight > convertRemToPixels(GanttScale.xlarge.puckHeight) * 1.5) {
    return PuckSize.X_LARGE;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.large.puckHeight) * 1.4) {
    return PuckSize.LARGE;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.medium.puckHeight) * 1.3) {
    return PuckSize.MEDIUM;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.small.puckHeight) * 1.2) {
    return PuckSize.SMALL;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.xsmall.puckHeight) * 1.1) {
    return PuckSize.X_SMALL;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.xxsmall.puckHeight)) {
    return PuckSize.XX_SMALL;
  } else {
    return PuckSize.XXX_SMALL;
  }
};

export const setPuckSizeGanttRowHeight = (
  enableEnhancedScalingFlag,
  bottomContentRef,
  viewConfigurationData,
  updateViewConfiguration,
  updatePuckSizePreference,
  setEnhancedGanttRowHeight,
  summaryPanelHeight,
) => {
  if (!enableEnhancedScalingFlag) {
    return;
  }

  if (bottomContentRef) {
    let height = 0;
    if (bottomContentRef.current && viewConfigurationData.numberOfAircrafts) {
      height = bottomContentRef.current.getBoundingClientRect().height + (summaryPanelHeight ?? 0);

      if (height > 0) {
        const currentScaling = viewConfigurationData;
        const ganttRowHeight = height / currentScaling.numberOfAircrafts;
        const rightPuckSize = getRightPuckSize(ganttRowHeight);

        if (rightPuckSize !== viewConfigurationData.puckSize) {
          updateViewConfiguration({ puckSize: rightPuckSize });
          updatePuckSizePreference(rightPuckSize);
        }

        setEnhancedGanttRowHeight(ganttRowHeight);
      }
    }
  }
};

export default getRightPuckSize;
