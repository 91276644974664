import React from 'react';
import { ReactComponent as CatStatusIconDark } from '../../../../assets/images/cloud-solid-white.svg';
import { ReactComponent as EtopsIconDark } from '../../../../assets/images/water-solid-white.svg';
import { ReactComponent as CertsIconDark } from '../../../../assets/images/file-regular-white.svg';
import { ReactComponent as WifiIconDark } from '../../../../assets/images/wifi-solid-white.svg';
import { ReactComponent as PlugIconDark } from '../../../../assets/images/plug-solid-white.svg';
import { ReactComponent as CatStatusIconLight } from '../../../../assets/images/cloud-solid-black.svg';
import { ReactComponent as EtopsIconLight } from '../../../../assets/images/water-solid-black.svg';
import { ReactComponent as CertsIconLight } from '../../../../assets/images/file-regular-black.svg';
import { ReactComponent as WifiIconLight } from '../../../../assets/images/wifi-solid-black.svg';
import { ReactComponent as PlugIconLight } from '../../../../assets/images/plug-solid-black.svg';
import { ThemeMode } from '../../../../lib/constants';
import './AircraftLabel.css';

export const TailNumberIndicatorTypes = {
  ETOPS_INDICATOR: 'showEtopsIndicator',
  WIFI_INDICATOR: 'showWifiInoperableIndicator',
  CAT_STATUS_INDICATOR: 'showCatStatusIndicator',
  MISSING_CERTIFICATES_INDICATOR: 'showMissingCertificatesIndicator',
  PLUG: 'plug',
};

const mapIndicatorsToIcons = {
  showCatStatusIndicator: {
    light: CatStatusIconLight,
    dark: CatStatusIconDark,
  },
  showEtopsIndicator: {
    light: EtopsIconLight,
    dark: EtopsIconDark,
  },
  showMissingCertificatesIndicator: {
    light: CertsIconLight,
    dark: CertsIconDark,
  },
  plug: {
    light: PlugIconLight,
    dark: PlugIconDark,
  },
  showWifiInoperableIndicator: {
    light: WifiIconLight,
    dark: WifiIconDark,
  },
};

const IconContainer = ({ children }) => <div className="aircraft-label-icon-container">{children}</div>;
const TailNumberIndicators = ({ tailNumberIndicators, currentTheme, totalRows }) => {
  const darkIcons = [ThemeMode.HIGH_CONTRAST, ThemeMode.MODERN_DARK, ThemeMode.CLASSIC].includes(currentTheme);

  return (
    <div className={totalRows === 1 ? 'aircraft-label-icons' : 'multi-row-label-icon'} data-cy="tail-number-indicators">
      {Object.keys(mapIndicatorsToIcons).map((key) => {
        const currentIndicatorIcons = mapIndicatorsToIcons[key];

        const Icon = darkIcons ? currentIndicatorIcons.dark : currentIndicatorIcons.light;
        return (
          <IconContainer key={key}>
            {tailNumberIndicators[key] ? (
              <Icon className="aircraft-label-icon" data-cy="tailnumberindicator-icon" />
            ) : (
              <></>
            )}
          </IconContainer>
        );
      })}
    </div>
  );
};

export default TailNumberIndicators;
