import PropTypes from 'prop-types';
import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import { mapFlightDataToOutput } from '../../../../lib/swapUtil';
import './SwapEmptyAircraftContainer.css';
import { useMultiSelectedFlightGroupStore } from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders an empty aircraft container to trigger swap drop
 * @param {*} props
 * @returns
 */
export default function SwapEmptyAircraftContainer(props) {
  const { flightLegGroup } = useMultiSelectedFlightGroupStore();
  const swapModeDispatch = useSwapModeDispatch();
  const dragItem = useDragItemStore();
  const { data, aircraftRegistration, disableSwapSubmit } = props;

  const [dropRef] = useDropItem({
    dropItemKey: `${aircraftRegistration}-swaps-empty-aircraft-container`,
    data: data,
    onDrop: () => {
      if (disableSwapSubmit) {
        return;
      }

      if (
        flightLegGroup[aircraftRegistration] != undefined &&
        flightLegGroup[aircraftRegistration]?.some((x) => x.flightLegKey == data?.flightLegKey)
      ) {
        let selectedFlights = flightLegGroup[aircraftRegistration];
        for (var flight of selectedFlights) {
          swapModeDispatch.updateMoveFlightLeg({
            flightData: [flight],
            aircraftRegistration: aircraftRegistration,
          });
        }
      } else {
        swapModeDispatch.updateMoveFlightLeg({
          flightData: [...dragItem.data.filter((f) => !f.groundFlag).map((f) => mapFlightDataToOutput(f))],
          aircraftRegistration: aircraftRegistration,
        });
      }
    },
  });

  return (
    <div ref={dropRef} className="swap-empty-aircraft-container" data-cy="swap-empty-aircraft-container">
      {/* empty container */}
    </div>
  );
}

SwapEmptyAircraftContainer.propTypes = {
  data: PropTypes.object,
  aircraftRegistration: PropTypes.string.isRequired,
  disableSwapSubmit: PropTypes.bool.isRequired,
};
