import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';
import './SingleSelectDropdown.css';

const useStyles = makeStyles((theme) => ({
  selectStyle: {
    background: '',
    border: '2px solid var(--secondary-border-color)',
    borderRadius: '.3rem',
    height: '4.4rem',
    width: '18.4rem',
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      background: 'var(--dropdown-menu-background)',
      borderRadius: '.3rem',
      border: '2px solid var(--secondary-border-color)',
      boxShadow: '0rem .1rem .2rem 0rem rgba(169, 169, 169, 0.5)',
      maxHeight: '52.09rem',
      maxWidth: '40.0rem',
      transform: 'translateX(0%) translateY(0%)',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};
const itemStyle = {
  lineHeight: '1.568rem',
};

// Chevron down icon for dropdown menu
const Icon = () => <FontAwesomeIcon className="menu-icon MuiSelect-icon" icon={faChevronDown} />;

/**
 * Dropdown is a Controlled Component and renders a drop-down select input
 * @param {string} displayText - the Text value on this drop down
 * @param {Array} displayList - a list of objects containing a label and value for each dropdown item
 * @param {func} onChange - callback function when the input is updated
 * @param {fun}  onKeyDown -callback function when the input is updated key space
 * @param {bool} isDisabled - whether or not this input is disabled
 * @param {string} className - an additional custom class name
 * @param {string} dataCyTag - test id attribute
 */

const SingleSelectDropdown = ({
  displayText,
  displayList = [],
  displayList2 = null,
  divider = false,
  onChange,
  onKeyDown,
  isDisabled,
  className,
  dataCyTag,
  customIcon = null,
}) => {
  const inputProps = {};
  const classes = useStyles();
  const containerProps = {
    className: `single-select-dropdown-container ${className ? ' ' + className : ''}`,
    'data-cy': `${dataCyTag}`,
  };

  inputProps['data-cy'] = `${dataCyTag}-input`;
  MenuProps['className'] = `${className}-popover`;

  const IconComponent = customIcon
    ? () => (
        <>
          <div className="custom-icon MuiSelect-icon"> {customIcon} </div>
          <Icon />
        </>
      )
    : Icon;

  return (
    <div {...containerProps}>
      <Select
        className={`${classes.selectStyle} ${className}`}
        disabled={isDisabled}
        value={displayText}
        disableUnderline="true"
        onChange={onChange}
        IconComponent={IconComponent}
        MenuProps={MenuProps}
        inputProps={inputProps}
      >
        {displayList.map((ele) => (
          <MenuItem
            className={`${className}-MuiMenu-list`}
            data-cy={`menu-item-${ele.name}`}
            key={ele.key}
            value={ele.key}
            style={itemStyle}
            onKeyDown={onKeyDown}
          >
            {ele.name}
          </MenuItem>
        ))}
        {divider}
        {displayList2 &&
          displayList2.map((ele) => (
            <MenuItem
              className={`${className}-MuiMenu-list`}
              data-cy={`menu-item-${ele.name}`}
              key={ele.key}
              value={ele.key}
              style={itemStyle}
              onKeyDown={onKeyDown}
            >
              {ele.name}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

SingleSelectDropdown.propTypes = {
  displayText: PropTypes.string,
  displayList: PropTypes.array,
  onChange: PropTypes.func,
};

export default SingleSelectDropdown;
