import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { utcToStationLocalTime } from '../../../../../../lib/dateTimeUtils';
import { TimeDisplay } from '../../../../../Shared/TimeDisplay/TimeDisplay';
import '../SummaryAircraftTab.css';
import { useAirportsContext } from '../../../../../../contexts/AirportsContext/AirportsContext';

/** SummaryAircraftInfoBlock renders the details of an aircraft tab.
 * @param {string} isAircraftDate - date and time of the aircraft tab
 * @param {string} aircraftData - note of the aircraft tab
 * @param {string} isAircraftTime - time of the aircraft tab
 * @param {string} dateTimeformat - format date and time
 * @returns SummaryAircraftInfoBlock */
const SummaryAircraftInfoBlock = ({
  aircraftData,
  dateTimeFormat,
  title,
  isDateField = false,
  isTimeField = false,
  station,
}) => {
  const [stationTimeZone, setStationTimeZone] = useState(null);
  const { isLoading, getAirportDetail } = useAirportsContext();

  const dataCyTag = `aircraft-tab-${title?.toLowerCase() || 'time'}`;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const airportObject = getAirportDetail(station);

    if (airportObject != null) {
      setStationTimeZone(airportObject.airportTimeZone);
    }
  }, [isLoading, getAirportDetail, station]);

  return (
    <div
      className={`aircraft-tab-${isDateField ? 'date' : isTimeField ? 'time' : 'text'} ${title}`}
      data-cy={dataCyTag}
    >
      {isDateField ? (
        stationTimeZone ? (
          utcToStationLocalTime(aircraftData, stationTimeZone, dateTimeFormat)
        ) : (
          '-'
        )
      ) : isTimeField ? (
        <TimeDisplay utcTime={aircraftData} station={station} dataCyTag={`${dataCyTag}-timedisplay-zulu`} />
      ) : (
        aircraftData
      )}
    </div>
  );
};

SummaryAircraftInfoBlock.propTypes = {
  aircraftData: PropTypes.string,
  isAircraftDate: PropTypes.bool,
  isAircraftTime: PropTypes.bool,
  dateTimeformat: PropTypes.string,
};

export default SummaryAircraftInfoBlock;
