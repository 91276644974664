import React, { useCallback, useState, useEffect } from 'react';
import Button from '../../Shared/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { PuckSize } from '../../../lib/constants';
import { useUserPreferencesFunctions } from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import {
  useViewConfigurationDispatch,
  useViewConfigurationStore,
} from '../../../hooks/useViewConfigurationStore/useViewConfigurationStore';

import './VerticalSpacingZoom.css';

/**
 * The VerticalSpacingZoom component is the toggle container.
 * It provides the zoom in/out scaling behavior for interacting GanttChart.
 * Icon - zoom-in/minus - it controls options such as zoom-x-large, zoom-large, zoom-medium, zoom-small, zoom-x-small.
 *      - It will disable once it reaches zoom-x-small option.
 * Icon - zoom-out/plus - initially, it shows as disabled then it will be enabled once zoom-in option got triggered and set with new zoomVal.
 *      - it controls options such as zoom-x-small, zoom-small, zoom-medium, zoom-large, zoom-x-large
 * The updated zoomVal will be available through ganttScaleSettings of useGanttScaleSetting() hook.
 * @returns {VerticalSpacingZoom} component.
 */

export const VerticalSpacingZoom = () => {
  const { updatePuckSizePreference } = useUserPreferencesFunctions();
  const { updateViewConfiguration } = useViewConfigurationDispatch();
  const viewConfigurationData = useViewConfigurationStore();

  /* Given the zoom value name, return the zoom value int */
  const getZoomValue = useCallback((valName) => {
    switch (valName) {
      case PuckSize.XXX_SMALL:
        return -1;
      case PuckSize.XX_SMALL:
        return 0;
      case PuckSize.X_SMALL:
        return 1;
      case PuckSize.SMALL:
        return 2;
      case PuckSize.MEDIUM:
        return 3;
      case PuckSize.LARGE:
        return 4;
      case PuckSize.X_LARGE:
        return 5;
      default:
        return 5;
    }
  }, []);

  /* Given the zoom value, get the zoom value name */
  const getZoomValueName = useCallback((val) => {
    switch (val) {
      case -1:
        return PuckSize.XXX_SMALL;
      case 0:
        return PuckSize.XX_SMALL;
      case 1:
        return PuckSize.X_SMALL;
      case 2:
        return PuckSize.SMALL;
      case 3:
        return PuckSize.MEDIUM;
      case 4:
        return PuckSize.LARGE;
      case 5:
        return PuckSize.X_LARGE;
      default:
        return PuckSize.X_LARGE;
    }
  }, []);

  // If ganttScale still loading,  use the default scale size or X-LARGE (5).
  // Otherwise, use the users preference
  const [zoomVal, setZoomVal] = useState(getZoomValue(viewConfigurationData.puckSize));

  /* Handle descresing zoomVal conditionally */
  const zoomInHandler = () => {
    if (zoomVal > 1) {
      updateViewConfiguration({ puckSize: getZoomValueName(zoomVal - 1) }); // updating the view configuration redux store
      updatePuckSizePreference(getZoomValueName(zoomVal - 1));
      setZoomVal(zoomVal - 1);
    }
  };

  /* Handle increasing zoomVal conditionally */
  const zoomOutHandler = () => {
    if (zoomVal < 5) {
      updateViewConfiguration({ puckSize: getZoomValueName(zoomVal + 1) }); // updating the view configuration redux store
      updatePuckSizePreference(getZoomValueName(zoomVal + 1));
      setZoomVal(zoomVal + 1);
    }
  };

  useEffect(() => {
    const newZoomVal = getZoomValue(viewConfigurationData.puckSize);
    if (newZoomVal !== zoomVal) {
      setZoomVal(newZoomVal);
    }
  }, [viewConfigurationData.puckSize]);

  return (
    <div className="zoom-container" data-cy="zoom-container">
      <Button
        variant="secondary"
        data-cy="zoom-in-button"
        className="nav-filters-button zoom-in"
        onClick={zoomInHandler}
        isDisabled={zoomVal === 1}
      >
        <FontAwesomeIcon data-cy="zoom-in" className="search-minus icon nav-icon" icon={faSearchMinus} />
      </Button>
      <div className="vertical-line"></div>
      <Button
        variant="secondary"
        data-cy="zoom-out-button"
        className="nav-filters-button zoom-out"
        onClick={zoomOutHandler}
        isDisabled={zoomVal === 5}
      >
        <FontAwesomeIcon data-cy="zoom-out" className="search-plus icon nav-icon" icon={faSearchPlus} />
      </Button>
    </div>
  );
};
