import { SortActions } from '../actionTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SessionStorageKeys } from '../../lib/constants';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';
import { isNullOrUndefined } from '../../lib/utils';

dayjs.extend(utc);

/**
 * Gets the initial state for the sort store.
 * @returns the initial state for the sort store
 */
export const getInitialSortState = (id) => {
  let initialState = { sortFilter: SortActions.DEFAULT_PRIORITY };
  // First try to get the initial state from session storage
  try {
    const savedFilterStates = getSessionStorageItem(SessionStorageKeys.SORT_FILTER);
    if (savedFilterStates && !isNullOrUndefined(id) && Object.keys(savedFilterStates).includes(id.toString())) {
      initialState = savedFilterStates[id];
    } else if (savedFilterStates && Object.keys(savedFilterStates).includes('sortFilter')) {
      initialState = savedFilterStates;
    }
  } catch (error) {
    console.error('Error parsing saved filter from session storage', error);
  }
  return initialState;
};

const initialState = getInitialSortState();

/**
 * Given the previous state and action payload, returns the new state for the sort store
 */
const sortReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT': {
      return getInitialSortState(action.id);
    }
    case SortActions.DEFAULT_PRIORITY:
    case SortActions.ETD_PRIORITY:
    case SortActions.ETA_PRIORITY:
      return {
        ...state,
        sortFilter: action.type,
      };
    default:
      return state;
  }
};

export default sortReducer;
