import { IrropsCode, PhaseOfFlight } from '../lib/constants';
import { datetimeIsBefore, getDateForOperationalTz, getTimeDifference } from './dateTimeUtils';
import { filterFlightsForDoubleClick, getPhaseOfFlight } from '../lib/utils';
/**
 * Converts puck data to swap output format
 * @param {*} data
 * @returns
 */
export const mapFlightDataToOutput = (data, isMoveFlight = false) => {
  const {
    dest,
    orig,
    scheduledOrigin,
    scheduledDestination,
    aircraft,
    departureCount,
    flightNumber,
    airline,
    departureDate,
    isEtopsFlight,
    departure,
  } = data;

  return {
    actualDestination: dest,
    actualOrigin: orig,
    scheduledOrigin: scheduledOrigin,
    scheduledDestination: scheduledDestination,
    aircraftRegistration: aircraft,
    departureCount: departureCount,
    flightNumber: flightNumber,
    operatingAirline: airline,
    scheduledOperatingDateUTC: departureDate,
    isETOPSRequired: isEtopsFlight,
    scheduledOperatingDateTimeUTC: departure,
    moveFromAircraft: isMoveFlight ? aircraft : '',
  };
};

export const mapFlightDataToOutputWithKey = (data, isMoveFlight = false) => {
  const {
    dest,
    orig,
    scheduledOrigin,
    scheduledDestination,
    aircraft,
    departureCount,
    flightNumber,
    airline,
    departureDate,
    flightLegKey,
    isEtopsFlight,
    departure,
  } = data;

  return {
    actualDestination: dest,
    actualOrigin: orig,
    scheduledOrigin: scheduledOrigin,
    scheduledDestination: scheduledDestination,
    aircraftRegistration: aircraft,
    departureCount: departureCount,
    flightNumber: flightNumber,
    operatingAirline: airline,
    scheduledOperatingDateUTC: departureDate,
    flightLegKey: flightLegKey,
    isETOPSRequired: isEtopsFlight,
    scheduledOperatingDateTimeUTC: departure,
    moveFromAircraft: isMoveFlight ? aircraft : '',
  };
};

/**
 * Get flight legs on or before the targetEtd on the same day
 * @param {object} puck - puck after which the pucks along the flight line are expected
 * @param {array} scheduledPuckConfigs - array of flights to check
 * @param {boolean} ctrlKey - boolean to show ctr key
 * @param {boolean} showAll - boolean to show all the pucks with default value false
 * @returns
 */
export const getFlightLine = (puck, scheduledPuckConfigs, ctrlKey, showAll = false) =>
  scheduledPuckConfigs
    .filter((f) => {
      const data = f.flightPuckData;
      if (showAll) {
        return data && data.aircraft === puck.aircraft;
      }
      return (
        data &&
        !!(
          data.aircraft === puck.aircraft &&
          data.std &&
          !datetimeIsBefore(data.std, puck.std) &&
          (!ctrlKey || getDateForOperationalTz(data.std) === getDateForOperationalTz(puck.std))
        )
      );
    })
    .map((f) => f.flightPuckData);

/**
 * Get flight legs between two selected pucks
 * @param {*} flightLegGroup - currently selected flights
 * @param {*} allFlightsAlongLine - flights in row
 * @param {*} data - current puck
 * @returns
 */
export const getFlightsInBetweenForMultiSelectGroup = (flightLegGroup, allFlightsAlongLine, data) => {
  let currentSelectedFlights = flightLegGroup[data.aircraft];
  let pucksInBetween = [];

  if (currentSelectedFlights != undefined && currentSelectedFlights.length == 1) {
    let leftPuckIndex = allFlightsAlongLine.findIndex(
      (x) => x.flightLegKey == flightLegGroup[data.aircraft][0].flightLegKey,
    );
    let rightPuckIndex = allFlightsAlongLine.findIndex((x) => x.flightLegKey == data.flightLegKey);

    if (leftPuckIndex != -1 && rightPuckIndex != -1 && leftPuckIndex < rightPuckIndex) {
      for (var value of allFlightsAlongLine) {
        if (allFlightsAlongLine[rightPuckIndex].flightLegKey == value.flightLegKey) {
          break;
        }
        if (
          getTimeDifference(allFlightsAlongLine[leftPuckIndex].departure, value.departure) > 0 &&
          getTimeDifference(value.arrival, allFlightsAlongLine[rightPuckIndex].arrival) > 0
        ) {
          if (value.irropsCode == IrropsCode.CANCELLED_FLIGHT || !filterFlightsForDoubleClick(value)) {
            pucksInBetween = null;
            break;
          }
          pucksInBetween.push(value);
        }
      }
    }
    return pucksInBetween;
  }

  return [];
};

/**
 * Determines if the puck can be dragged or dropped
 * @param {Object} data - puck data
 * @returns {boolean} - true if puck can be dragged or dropped
 */
export const getCanDragOrDrop = (data) => {
  if (data.irropsCode === 'CXL') {
    return false;
  }
  let flightPhase = getPhaseOfFlight(data);
  return flightPhase == PhaseOfFlight.PRE_FLIGHT;
};

/**
 * Combines flightline data with puck data if ctrlKey is true
 * @param {boolean} ctrlKey - Is control key pressed
 * @param {Object} data - puck data
 * @param {array} getFlightLineForFlightPuck - function to get flightline data
 * @returns
 */
export const getFlightPuckDragData = (ctrlKey, data, getFlightLineForFlightPuck) => {
  let dragData = [data];
  dragData = [
    ...(dragData.length > 1 ? dragData : []),
    ...getFlightLineForFlightPuck(ctrlKey).filter((f) => getCanDragOrDrop(f)),
  ];
  return dragData;
};

/**
 * Creates a payload for swapping flight legs.
 * @param {Array} swappedFlightLegs - The array of flight legs to be swapped.
 * @returns {Array} - The payload containing the swapped flight legs.
 */
export const createSwapWarningsPayload = (swappedFlightLegs) => {
  const aircraftLength = [...new Set(swappedFlightLegs.map((flight) => flight.aircraftRegistration))];
  let swappedAircraftList = [];

  const mapFlightToLeg = (flight) => ({
    scheduledOperatingDateUTC: flight.scheduledOperatingDateTimeUTC,
    flightNumber: flight.flightNumber.toString(),
    origin: flight.actualOrigin,
    destination: flight.actualDestination,
    departureCount: flight.departureCount,
    isETOPSRequired: flight.isETOPSRequired,
  });

  if (aircraftLength.length === 1) {
    swappedAircraftList.push({
      operatingAirlineCode: swappedFlightLegs[0].operatingAirline,
      aircraftRegistration: swappedFlightLegs[0].swapAircraftRegistration,
      flightLegs: swappedFlightLegs.map((flight) => mapFlightToLeg(flight)),
    });

    swappedAircraftList.push({
      operatingAirlineCode: swappedFlightLegs[0].operatingAirline,
      aircraftRegistration: swappedFlightLegs[0].aircraftRegistration,
      flightLegs: [],
    });
  } else {
    swappedFlightLegs.forEach((flight) => {
      let indx = swappedAircraftList.findIndex((item) => item.aircraftRegistration === flight.swapAircraftRegistration);
      if (indx !== -1) {
        swappedAircraftList[indx].flightLegs.push(mapFlightToLeg(flight));
      } else {
        swappedAircraftList.push({
          operatingAirlineCode: flight.operatingAirline,
          aircraftRegistration: flight.swapAircraftRegistration,
          flightLegs: [mapFlightToLeg(flight)],
        });
      }
    });
  }
  return swappedAircraftList;
};

/**
 * Creates a payload for swap warning with the provided swapped flight legs and previous flight leg.
 * @param {Array} swappedFlightLegs - Array of swapped flight legs.
 * @param {Object} previousFlightLeg - Previous flight leg details.
 * @returns {Array} - Array of swapped aircraft list with flight legs and previous flight leg details.
 */
export const createSwapWarningPayloadV2 = (swappedFlightLegs, previousFlightLeg) => {
  const aircraftLength = [...new Set(swappedFlightLegs.map((flight) => flight.aircraftRegistration))];
  let swappedAircraftList = [];

  const mapFlightToLeg = (flight) => ({
    scheduledOperatingDateUTC: flight.scheduledOperatingDateUTC,
    flightNumber: flight.flightNumber.toString(),
    origin: flight.actualOrigin,
    destination: flight.actualDestination,
    departureCount: flight.departureCount,
    isETOPSRequired: flight.isETOPSRequired,
    departureTimeUTC: flight.scheduledOperatingDateTimeUTC,
  });

  const mapPrevFlightLeg = (flight) => ({
    scheduledOperatingDateUTC: flight.departureDate,
    flightNumber: flight.flightNumber.toString(),
    origin: flight.orig,
    destination: flight.dest,
    departureCount: flight.departureCount,
    isETOPSRequired: flight.isEtopsFlight,
    departureTimeUTC: flight.departure,
  });

  if (aircraftLength.length === 1) {
    swappedAircraftList.push({
      operatingAirlineCode: swappedFlightLegs[0].operatingAirline,
      aircraftRegistration: swappedFlightLegs[0].swapAircraftRegistration,
      flightLegs: swappedFlightLegs.map((flight) => mapFlightToLeg(flight)),
      previousFlightLeg: previousFlightLeg?.length
        ? previousFlightLeg.map((flight) => mapPrevFlightLeg(flight))[0]
        : null,
    });

    swappedAircraftList.push({
      operatingAirlineCode: swappedFlightLegs[0].operatingAirline,
      aircraftRegistration: swappedFlightLegs[0].aircraftRegistration,
      flightLegs: [],
      previousFlightLeg: null,
    });
  } else {
    swappedFlightLegs.forEach((flight) => {
      let indx = swappedAircraftList.findIndex((item) => item.aircraftRegistration === flight.swapAircraftRegistration);
      if (indx !== -1) {
        swappedAircraftList[indx].flightLegs.push(mapFlightToLeg(flight));
      } else {
        swappedAircraftList.push({
          operatingAirlineCode: flight.operatingAirline,
          aircraftRegistration: flight.swapAircraftRegistration,
          flightLegs: [mapFlightToLeg(flight)],
          previousFlightLeg: previousFlightLeg?.length
            ? previousFlightLeg
                ?.filter((leg) => leg.aircraft === flight.swapAircraftRegistration)
                .map((flight) => mapPrevFlightLeg(flight))[0]
            : null,
        });
      }
    });
  }

  return swappedAircraftList;
};

/**
 * Called when dropping pucks onto station or standby pucks to update redux store
 * @param {object} dragItem
 * @param {array} flightLegGroup
 * @param {string} aircraft
 * @param {function} updateMoveFlightLeg
 * @param {function} removeAllMultiSelectedFlightGroup
 */
export const onDropMoveFlights = (
  dragItem,
  flightLegGroup,
  aircraft,
  updateMoveFlightLeg,
  removeAllMultiSelectedFlightGroup,
) => {
  const filteredFlightData = dragItem.ctrlKey
    ? [...dragItem.data.filter((f) => !f.groundFlag).map((f) => mapFlightDataToOutputWithKey(f, true))]
    : [
        ...dragItem.data
          .slice(0, 1)
          .filter((f) => !f.groundFlag)
          .map((f) => mapFlightDataToOutputWithKey(f, true)),
      ];

  if (
    flightLegGroup[filteredFlightData[0]?.aircraftRegistration]?.some(
      (x) => x.flightLegKey == filteredFlightData[0].flightLegKey,
    )
  ) {
    let selectedFlights = flightLegGroup[filteredFlightData[0].aircraftRegistration];

    const flightDataToSwap = selectedFlights
      .filter((f) => !f.groundFlag)
      .map((f) => mapFlightDataToOutputWithKey(f, true));

    updateMoveFlightLeg({
      flightData: flightDataToSwap,
      aircraftRegistration: aircraft,
    });
    removeAllMultiSelectedFlightGroup();
  } else {
    updateMoveFlightLeg({
      flightData: filteredFlightData,
      aircraftRegistration: aircraft,
    });
  }
};
