import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useHistory } from 'react-router-dom';
import { faUserCircle, faCaretRight, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from '../../Shared/Button/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from '../../Shared/Modal/Modal';
import { deleteUserAssignments } from '../../../services/apiClient/assignmentsApi/assignmentsApi';
import { useAuthentication } from '../../../contexts/AuthenticationContext/AuthenticationContext';
import './UserMenu.css';
import { CSSTransition } from 'react-transition-group';
import { useThemeContext } from '../../../contexts/ThemeContext/ThemeContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { ThemeMode, Treatment, SessionStorageKeys } from '../../../lib/constants';
import { getThemeDisplayText } from '../../../lib/displayUtils';
import { useConfigDispatch } from '../../../hooks/useConfigStore/useConfigStore';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';

const ArrowWithCircle = () => {
  return <FontAwesomeIcon className="arrowWithCircle" icon={faArrowLeft} />;
};

const CaretRight = () => {
  return <FontAwesomeIcon icon={faCaretRight} size="1x" className="faCaret" />;
};

/**
 * User icon for toggling menu
 */
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <span ref={ref} data-cy="user-icon" onClick={onClick}>
    <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
  </span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value] = useState('');
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <div className="user-menu-dropdown-list">
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children.toLowerCase().startsWith(value),
        )}
      </div>
    </div>
  );
});

const UserMenu = (assignments) => {
  const { authSignOut: authSignOutFunc } = useAuthentication();
  const roleAssignments = assignments.assignments;
  let queryClient = useQueryClient();

  const { currentTheme, updateTheme } = useThemeContext();

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [props, setProps] = useState({ show: false });

  // feature flag
  const { showFeature } = useFeatureFlag();
  const modernThemeFlag = showFeature(Treatment.DARK_MODE);
  const showConfigs = showFeature(Treatment.SAVABLE_CONFIGS);

  const { openSaveConfigModal } = useConfigDispatch();
  const { isSwapModeActive } = useSwapModeStore();
  const history = useHistory();

  const location = useLocation();
  const isDashboard = location.pathname.startsWith('/dashboard');

  const isSystemAdmin = roleAssignments.length === 0;

  const configurationPagePath = '/configuration';

  let logOutModalContent = (
    <div className="log-out-modal-text" data-cy="log-out-modal-text">
      {roleAssignments && roleAssignments.length > 0 ? (
        <span data-cy="log-out-assignment-end">
          Signing out ends your assignment to{' '}
          <strong>
            {roleAssignments
              .map((a) => a.name)
              .filter((value, index, self) => self.indexOf(value) === index)
              .join(', ')}
          </strong>
          .
        </span>
      ) : (
        <span>Selecting "Confirm" will sign you out of AOC.</span>
      )}
    </div>
  );

  const createSubMenuItem = (className, theme, item) => {
    var dataCy = 'unchecked-container';
    if (theme === currentTheme) {
      dataCy = 'check-mark-container';
    }

    return (
      <Dropdown.Item
        className={className}
        id={`${theme}-dropdown-item`}
        data-cy={dataCy}
        onClick={() => updateTheme(theme)}
      >
        <div className="check-mark-container">
          {theme === currentTheme && <FontAwesomeIcon icon={faCheck} size="1x" className="faCheck" />}
        </div>
        <div>{item}</div>
      </Dropdown.Item>
    );
  };

  const createSubMenu = () => {
    var classic = createSubMenuItem('subMenuItem', ThemeMode.CLASSIC, getThemeDisplayText(ThemeMode.CLASSIC));
    var highContrast = createSubMenuItem(
      'subMenuItem',
      ThemeMode.HIGH_CONTRAST,
      getThemeDisplayText(ThemeMode.HIGH_CONTRAST),
    );

    let themeList = [classic];

    if (modernThemeFlag) {
      themeList.push(highContrast);
    }

    return (
      <div className="subMenu">
        {themeList.map((item, i) => (
          <React.Fragment key={`theme-item-${i}`}>{item}</React.Fragment>
        ))}
      </div>
    );
  };

  const subMenu = createSubMenu();
  const handleSubMenuOpen = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleOnToggle = (show, metadata) => {
    if (metadata) {
      const target = metadata.target;
      if (target.outerHTML.includes('user-circle') > 0 || target.outerHTML.includes('currentColor') > 0) {
        setProps({ show: !show });
      } else {
        setProps({ show: false });
        setSubMenuOpen(false);
      }
    }
  };

  // handles when log out confirm button is clicked
  const handleLogOutConfirmClick = async () => {
    window.sessionStorage.removeItem(SessionStorageKeys.FILTER);
    await deleteUserAssignments();
    await authSignOutFunc();
  };

  // handles when log out cancel button is clicked
  const handleLogOutCancelClick = () => {
    setShowLogoutModal(false);
  };

  const logOutConfirmButton = (
    <Button variant="primary" onClick={handleLogOutConfirmClick} data-cy="logout-confirm-button">
      Confirm
    </Button>
  );

  const navigateSaveConfig = () => {
    queryClient.invalidateQueries('userconfigurations');
    openSaveConfigModal();
    history.push(configurationPagePath);
  };

  const navigateOpenConfig = () => {
    queryClient.invalidateQueries('userconfigurations');
    history.push(configurationPagePath);
  };

  let footerContent = [logOutConfirmButton];

  return (
    <>
      <Dropdown {...props} className="user-menu" onToggle={(isOpen, metadata) => handleOnToggle(props.show, metadata)}>
        <Dropdown.Toggle
          as={CustomToggle}
          data-cy="user-menu-dropdown"
          id="dropdown-custom-components"
        ></Dropdown.Toggle>
        <CSSTransition in={props.show} appear={true} timeout={300} classNames="fade">
          <Dropdown.Menu as={CustomMenu} className="dropdown-menu-right">
            {!subMenuOpen && (
              <Dropdown.Header
                id="menuHeader"
                bsPrefix="dropDownItem menu-header"
                href="#Theme"
                onClick={handleSubMenuOpen}
                data-cy="nav-dropdown-user-preferences"
              >
                <span>Theme</span>
                <CaretRight />
              </Dropdown.Header>
            )}

            {subMenuOpen && (
              <Dropdown.Header
                id="menuHeader"
                bsPrefix="noPaddingHeader"
                href="#Theme"
                onClick={handleSubMenuOpen}
                data-cy="nav-dropdown-user-preferences"
              >
                <div className="submenu-back-icon-container" data-cy="submenu-back-icon-container">
                  <ArrowWithCircle />
                </div>
                <span className="submenu-header-text">Theme</span>
              </Dropdown.Header>
            )}

            {subMenuOpen && (
              <CSSTransition in={subMenuOpen} appear={true} timeout={200} classNames="fade">
                <div className="test">{subMenu}</div>
              </CSSTransition>
            )}
            {showConfigs && !subMenuOpen && !isDashboard && !isSystemAdmin && (
              <React.Fragment>
                <Dropdown.Item
                  eventKey="3"
                  bsPrefix="dropDownItem"
                  onClick={navigateOpenConfig}
                  disabled={isSwapModeActive}
                  data-cy="nav-dropdown-open-config"
                >
                  Open Config
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  bsPrefix="dropDownItem"
                  onClick={navigateSaveConfig}
                  disabled={isSwapModeActive}
                  data-cy="nav-dropdown-save-config"
                >
                  Save Config
                </Dropdown.Item>
              </React.Fragment>
            )}
            {!subMenuOpen && (
              <Dropdown.Item
                eventKey="3"
                href="#"
                bsPrefix="dropDownItem"
                onClick={() => {
                  setShowLogoutModal(true);
                }}
                data-cy="nav-dropdown-log-out"
              >
                Sign out
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </CSSTransition>
      </Dropdown>

      <Modal
        show={showLogoutModal}
        title="Sign Out"
        body={logOutModalContent}
        footerButtons={footerContent}
        onHide={handleLogOutCancelClick}
        dataCyTag="log-out-modal"
        customCSSTag="log-out-modal"
        size="thin"
        draggable={false}
      />
    </>
  );
};

export default UserMenu;
