import React from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import SingleSelectDropdown from '../../Shared/Inputs/SingleSelectDropdown/SingleSelectDropdown';
import { useUserPreferencesFunctions } from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import {
  useViewConfigurationDispatch,
  useViewConfigurationStore,
} from '../../../hooks/useViewConfigurationStore/useViewConfigurationStore';
import './TimelineHoursDropdown.css';

const timelineHoursItems = [
  { key: '12', name: '12 Hours' },
  { key: '24', name: '24 Hours' },
  { key: '36', name: '36 Hours' },
  { key: '48', name: '48 Hours' },
  { key: '60', name: '60 Hours' },
  { key: '72', name: '72 Hours' },
  { key: '84', name: '84 Hours' },
  { key: '96', name: '96 Hours' },
];

/**
 * The Timeline Hours Dropdown component is Gantt Scaling - Timeline Hours Dropdown.
 * @returns timeline hours dropdown component
 */
const TimelineHoursDropdown = () => {
  const { updateTimelineHourPreference } = useUserPreferencesFunctions();
  const { updateViewConfiguration } = useViewConfigurationDispatch();
  const viewConfigurationData = useViewConfigurationStore();
  const { timelineHours } = viewConfigurationData;
  /**
   * Event trigger on change event of Timeline Hours Dropdown.
   * @param {any} event - Event triggern when the input is updated
   */
  const handleTimelineHoursSelection = (event) => {
    // update the view configuration redux store
    updateViewConfiguration({ timelineHours: parseInt(event.target.value) });
    // Save value to users' preferences
    updateTimelineHourPreference(event.target.value);
  };

  return (
    <div className="timeline-hours-container" data-cy="timeline-hours-container">
      {
        <SingleSelectDropdown
          displayText={timelineHours}
          displayList={timelineHoursItems}
          className="timeline-hours-filter"
          dataCyTag="timeline-hours"
          isDisabled={false}
          onChange={handleTimelineHoursSelection}
        />
      }
    </div>
  );
};

TimelineHoursDropdown.propTypes = {
  onChange: PropTypes.func,
};

export default withAppInsightsTracking(TimelineHoursDropdown);
