import { useDispatch } from 'react-redux';
import { SelectedFlightDetailsAction } from '../../redux/actionTypes';
import { useCallback } from 'react';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Selector for the entire selected flight store
 */
const selectSelectedItem = (state) => state.selectedFlightDetailsReducer;

/**
 * Custom hook for retrieving the entire selected flight store
 * @returns selector hook for retrieving selected flight store
 */
export const useSelectedItemStore = () => {
  return useSelector(selectSelectedItem);
};

export const useSelectedItemStoreWithEqualComparator = (isEqualFn) => {
  return useSelector(selectSelectedItem, isEqualFn);
};

export const isPuckSelected = (data, selectedFlight, type) => {
  const selectedFlightData = selectedFlight?.data;
  if (selectedFlightData) {
    switch (type) {
      case 'standby':
        return (
          selectedFlightData?.aircraft === data.aircraft &&
          selectedFlightData?.index === data.index &&
          selectedFlightData?.departureStation === data.departureStation
        );
      case 'block':
        return selectedFlightData?.groundEventKey === data.groundEventKey;
      case 'ots':
        return selectedFlightData?.groundEventKey === data.groundEventKey;
      case 'classic':
        return selectedFlightData?.flightLegKey === data.flightLegKey;
      default:
        return false;
    }
  }
  return null;
};

export const useIsSelected = (data, puckType) => {
  const selectedFlight = useSelectedItemStore();
  return isPuckSelected(data, selectedFlight, puckType);
};

/**
 * Custom hook for dispatching actions to update or clear the selected flight store
 * @returns object of dispatch actions to interact with the selected flight store
 */
export const useSelectedItemDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Callback to update the selected flight store
   * @param {Object} flightDetails - the updated flight details
   */
  const updateSelectedFlightDetails = useCallback(
    (payload) => {
      dispatch({ type: SelectedFlightDetailsAction.UPDATE, payload: { ...payload } });
    },
    [dispatch],
  );

  /**
   * Callback to clear the selected flight store
   */
  const clearSelectedFlightDetails = useCallback(() => {
    dispatch({ type: SelectedFlightDetailsAction.CLEAR });
  }, [dispatch]);

  return { updateSelectedFlightDetails, clearSelectedFlightDetails, useIsSelected };
};
