import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './CollapsibleRegion.css';

const CollapsibleRegion = ({ text, children, expand = false, disabled = false, onToggle }) => {
  const [open, setOpen] = React.useState(expand);

  useEffect(() => {
    setOpen(expand);
  }, [expand]);

  const handleClick = useCallback(() => {
    if (disabled) return;
    setOpen(!open);
    if (onToggle) onToggle(!open);
  }, [disabled, open]);

  return (
    <div className="collapse-region">
      <div className={`collapse-container ${disabled ? 'collapse-container-disabled' : ''}`}>
        <span role="button" onClick={handleClick} onKeyDown={handleClick} className={disabled ? 'disabled' : ''}>
          {text} &nbsp;
        </span>
        {open ? (
          <FontAwesomeIcon onClick={handleClick} className="collapse-chevron" icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon onClick={handleClick} className="collapse-chevron" icon={faChevronDown} />
        )}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

CollapsibleRegion.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CollapsibleRegion;
