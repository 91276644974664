import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { utcToStationLocalTime } from '../../../../../../../lib/dateTimeUtils';
import { TimeZones } from '../../../../../../../lib/constants';
import './FlightInfoTable.css';

const FlightInfoTable = ({ flightInfo, trip, date, columnNames = [] }) => {
  const displayHeader = flightInfo?.length > 0;
  const currentTimeZone = utcToStationLocalTime(new Date(date).toISOString(), TimeZones.PDT, 'z');
  return (
    <div style={{ padding: '1rem' }} className="flightInfo-table">
      <div className="trip-info">
        <span style={{ width: '3.5rem', fontWeight: 700 }}>TRIP {trip}</span> |{' '}
        <span style={{ width: '1rem' }}>TIMES: {currentTimeZone}</span>
      </div>
      {flightInfo.length === 0 && (
        <div className="no-matching-trips">Failed to retrieve Crew data. Please try again.</div>
      )}
      {flightInfo.length > 0 && (
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              {columnNames.map((columnName, index) => (
                <TableCell key={index} align="left" style={{ height: '3.5rem' }}>
                  {displayHeader && columnName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(flightInfo).map((info, index) => (
              <TableRow
                key={`${trip}-${info.aircraftRegistration}-${info.flightNumber}-${info.departureTime}-${index}`}
                data-cy={`${trip}-${info.aircraftRegistration}-${index}`}
              >
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {info.aircraftRegistration}
                </TableCell>
                <TableCell align="left" break autoWidth style={{ verticalAlign: 'top' }}>
                  {info.flightNumber}
                </TableCell>
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {info.origin}
                </TableCell>
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {utcToStationLocalTime(info.departureTime, TimeZones.PDT, 'HH:mm')}
                </TableCell>
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {info.destination}
                </TableCell>
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {utcToStationLocalTime(info.arrivalTime, TimeZones.PDT, 'HH:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

FlightInfoTable.propTypes = {
  trip: PropTypes.object.isRequired,
  flightInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.string.isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string),
};

export default FlightInfoTable;
