import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './FlightInfoTabs.css';
import FlightInfoTables from './FlightInfoTables/FlightInfoTables';
import { utcToStationLocalTime, getRelativeDate } from '../../../../../../lib/dateTimeUtils';
import { TimeZones } from '../../../../../../lib/constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`flightinfo-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function applyProps(index) {
  return {
    id: `flightinfo-tab-${index}`,
    'aria-controls': `flightinfo-tabpanel-${index}`,
  };
}

export default function FlightInfoTabs({ isDeadheadsExpanded, tripDetails, currentFlight }) {
  const [value, setValue] = useState(1);

  const { today, yesterday, tomorrow } = tripDetails;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columnNames = ['AIRCRAFT', 'FLIGHT', 'ORIGIN', 'DEPART', 'DESTIN', 'ARRIVAL'];

  const currentTimeZone = utcToStationLocalTime(new Date().toISOString(), TimeZones.PDT, 'z');

  const GetTabLabel = ({ day }) => {
    const date = getRelativeDate(day?.toLowerCase(), 'MM/DD');
    return (
      <div>
        <div>{day}</div>
        <div>
          {date} {currentTimeZone}
        </div>
      </div>
    );
  };

  return (
    <div className={`flight-info-tabs-container ${isDeadheadsExpanded ? 'deadheads-expanded' : ''}`}>
      <Tabs className="flightinfo-tabs" value={value} onChange={handleChange} aria-label="flight info tabs">
        <Tab className="flightinfo-tab" label=<GetTabLabel day="Yesterday" /> {...applyProps(0)} />
        <Tab className="flightinfo-tab" label=<GetTabLabel day="Today" /> {...applyProps(1)} />
        <Tab className="flightinfo-tab" label=<GetTabLabel day="Tomorrow" /> {...applyProps(2)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        {yesterday === null ? (
          <div>Failed to retrieve Crew data. Please try again</div>
        ) : (
          <FlightInfoTables
            flightInfoData={yesterday}
            columnNames={columnNames}
            currentFlight={currentFlight}
            isDeadheadsExpanded={isDeadheadsExpanded}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {today === null && <div>Failed to retrieve Crew data. Please try again</div>}
        {today != null && (
          <FlightInfoTables
            flightInfoData={today}
            columnNames={columnNames}
            currentFlight={currentFlight}
            isDeadheadsExpanded={isDeadheadsExpanded}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {tomorrow === null ? (
          <div>Failed to retrieve Crew data. Please try again</div>
        ) : (
          <FlightInfoTables
            flightInfoData={tomorrow}
            columnNames={columnNames}
            currentFlight={currentFlight}
            isDeadheadsExpanded={isDeadheadsExpanded}
          />
        )}
      </TabPanel>
    </div>
  );
}
FlightInfoTabs.propTypes = {
  isDeadheadsExpanded: PropTypes.bool,
  tripDetails: PropTypes.object,
  currentFlight: PropTypes.object,
};
