import { useEffect, useRef } from 'react';

/**
 * Hook for Enabling Double Click Functionality
 * @returns
 */
export const useDoubleClick = (callback, delay = 300) => {
  const isClickingRef = useRef(false);
  const timeoutRef = useRef(null);

  const handleClick = (...callbackArgs) => {
    if (!isClickingRef.current) {
      isClickingRef.current = true;
      timeoutRef.current = setTimeout(() => {
        isClickingRef.current = false;
      }, delay);
    } else {
      clearTimeout(timeoutRef.current);
      isClickingRef.current = false;
      callback(...callbackArgs); // Fire double click event
    }
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current); // Cleanup
  }, []); // Empty dependency array to run the effect only once

  return handleClick;
};
