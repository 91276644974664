/**
 * Calculates the view windows positions and sizes based on the given state, view dimensions, and number of columns.
 *
 * @param {Array<Object>} viewWindows - The array of view windows to calculate the positions and sizes for.
 * @param {number} viewWidth - The width of each view window.
 * @param {number} viewHeight - The height of each view window.
 * @param {number} columns - The number of columns to arrange the view windows in.
 * @returns {Array<Object>} An array of view windows with updated positions and sizes.
 */
export const calculateViewWindows = (viewWindows, viewWidth, viewHeight, columns = viewWindows.length) => {
  return viewWindows?.map((window, index) => {
    const x = index % columns;
    const y = Math.floor(index / columns);
    return {
      ...window,
      viewPosition: { x: x * viewWidth, y: y * viewHeight },
      viewSize: { width: viewWidth, height: viewHeight },
    };
  });
};
