import { useDispatch } from 'react-redux';
import { AutoRefreshAction } from '../../redux/actionTypes';
import { useCallback } from 'react';
import { AutoRefreshStorageKeys } from '../../lib/constants';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';
/**
 * Selector for the auto refresh store
 */
const selectAutoRefresh = (state) => state.autoRefreshReducer;

/**
 * Custom hook for retrieving the auto refresh store
 * @returns selector hook for retrieving auto refresh store
 */
export const useAutoRefreshStore = () => {
  return useSelector(selectAutoRefresh);
};

/**
 * Custom hook for dispatching actions to update auto refresh flags
 * @returns object of dispatch actions to interact with the auto refresh store
 */
export const useAutoRefreshDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Callback to update the autoRefresh flag for Gantt Chart
   */
  const updateAutoRefresh = useCallback(
    (key, isAutoRefreshEnabled) => {
      switch (key) {
        case AutoRefreshStorageKeys.GANTT:
          dispatch({
            type: AutoRefreshAction.UPDATE_GANTT_CHART,
            payload: { ganttChartRefresh: isAutoRefreshEnabled },
          });
          break;
        case AutoRefreshStorageKeys.FLIGHT_DETAIL_PANE:
          dispatch({
            type: AutoRefreshAction.UPDATE_FLIGHT_DETAIL_PANE,
            payload: { flightDetailPaneRefresh: isAutoRefreshEnabled },
          });
          break;
      }
    },
    [dispatch],
  );

  return { updateAutoRefresh };
};
