import axios from 'axios';
import appSettings from '../../appSettings';
import { getAccessToken } from '../authenticationService/authenticationService';
import mockApiInterceptor from '../../services/interceptors/interceptors';

export const axiosInstance = axios.create({
  // Default base URL to AOC
  // Override in specific *api.js module if baseUrl is different (i.e. Ground Events API)
  baseURL: appSettings.ALASKA_APIM_BASE_URL,
  timeout: 15000,
});

// this should not be imported other than for testing purposes
export const configureApiClient = async (config) => {
  const accessToken = await getAccessToken();
  config.headers = {
    common: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return config;
};

// Add a request interceptor
axiosInstance.interceptors.request.use(configureApiClient, (error) => Promise.reject(error));

// Add a response interceptor for qa and test (local and dev) environments only, not production
if (appSettings.environment === 'test' || appSettings.environment === 'qa') {
  const { mockResponse, mockError } = mockApiInterceptor();
  axiosInstance.interceptors.response.use(mockResponse, mockError);
}
