import React, { useState, useRef, useEffect } from 'react';
import { getTimeDifference } from '../../../lib/dateTimeUtils';
import { getAirlineName, getBlockPuckLabel, getOutOfServicePuckLabel } from '../../../lib/displayUtils';
import PropTypes from 'prop-types';
import './OutOfServiceGroundPuck.css';
import { AirlineFilter, GroundEventType, OutOfServiceState, PuckType, Treatment } from '../../../lib/constants';
import { elementHasOverflow, jsonEqual } from '../../../lib/utils';
import { useThemeContext } from '../../../contexts/ThemeContext/ThemeContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { useFocusedFlightLegDispatch } from '../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import FlightActionMenu from '../FlightActionMenu/FlightActionMenu';
import { getFlightPuckClassNames } from '../GanttChart/ganttHelpers';
import { useFlightNumberOverflow } from '../../../hooks/useGanttConfig/utils/utils';
import { useFlightLegStatus } from '../../../hooks/useFlightLeg/useFlightLeg';

dayjs.extend(utc);

const LabelType = {
  DEFAULT: 1,
  SHORT: 2,
  HIDDEN: 3,
};

const LongPuckRules = {
  REPEATED_HOUR: 12,
  REPEATED_START_HOUR: 19,
  DISCURD_HOUR: 7,
};

/**
 * GroundEventPuck currently represents an Out Of Service ground event puck
 * @param {Object} props
 * - groundEventKey
 * - groundEventStation
 * - groundEventType - GroundEventType enum
 * - start - ISO string datetime
 * - end - ISO string datetime
 * - state - OutOfServiceState enum
 * @returns
 */
const OutOfServiceGroundPuck = ({
  data,
  getFlightLineForFlightPuck,
  openDetailPane,
  airline,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
  isSelected,
}) => {
  const { groundEventKey, groundEventStation, groundEventType, start, end, state, puckType } = data;
  const isBlockPuck = puckType === PuckType.GROUND_UNAVAILABLE;
  const [labelType, setLabelType] = useState(LabelType.DEFAULT);
  const { showFeature } = useFeatureFlag();
  const flightNumberRef = useRef(null);
  const { hasMxMessage } = useFlightLegStatus(data);
  const { showFlightNumber } = useFlightNumberOverflow(flightNumberRef);

  const { clearFocusedFlightLeg } = useFocusedFlightLegDispatch();
  const isSummaryPanelAvailable = showFeature(Treatment.FLIGHT_DETAIL_SUMMARY);
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);

  const [showFlightActionMenu, setShowFlightActionMenu] = useState(false);

  const [showOverLay, setShowOverLay] = useState(false);

  /**
   * Returns the hours array based on repeated hours, in specified unit of time, between startDateTime and endDateTime
   * @param {string} startDateTime - ISO date string, i.e. "2020-09-23T02:11:00Z"
   * @param {string} endDateTime - ISO date string, i.e. "2020-09-23T02:11:00Z"
   * @param {boolean} returnFloat - specify if a float should be returned or not. Defaults to false
   * @returns {Array[number]}  final integer array with hours by difference between startDateTime and endDateTime.
   */
  const getHoursList = (startDateTime, endDateTime, returnFloat = false) => {
    const hoursDiff =
      startDateTime && endDateTime ? dayjs.utc(endDateTime).diff(dayjs.utc(startDateTime), 'hour', returnFloat) : 0;
    if (hoursDiff >= LongPuckRules.REPEATED_START_HOUR) {
      const arrayLength = Math.ceil(hoursDiff / LongPuckRules.REPEATED_HOUR);
      let tempHours = hoursDiff;
      const hoursArray = Array.from(new Array(arrayLength), (x, i) => {
        if (i < arrayLength - 1) {
          tempHours -= LongPuckRules.REPEATED_HOUR;
          return tempHours < LongPuckRules.DISCURD_HOUR
            ? LongPuckRules.REPEATED_HOUR + tempHours
            : LongPuckRules.REPEATED_HOUR;
        } else {
          return tempHours > LongPuckRules.DISCURD_HOUR ? tempHours : 0;
        }
      });
      return hoursArray.filter((val) => val !== 0);
    } else {
      return [];
    }
  };

  const groundEventPuckRef = useRef(null);

  const label = isBlockPuck
    ? getBlockPuckLabel(groundEventStation, labelType === LabelType.SHORT)
    : getOutOfServicePuckLabel(groundEventType, groundEventStation, state, labelType === LabelType.SHORT);
  const duration = getTimeDifference(start, end, 'minute');
  const hoursArray = getHoursList(start, end);
  const { currentTheme } = useThemeContext();
  const selectedFlightDetails = useSelectedItemStore();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();

  const puckStyle = {
    width: duration > 0 ? `calc(${duration} * var(--time-scale-minute))` : '2px',
    padding: duration > 0 ? null : '0px',
    overflow: 'hidden',
    display: hoursArray.length > 0 ? 'flex' : null,
  };

  useEffect(() => {
    const element = groundEventPuckRef?.current;
    if (elementHasOverflow(element)) {
      setLabelType((prevState) => {
        // Try to render with a shortened label
        // If the short label is still too long, then render again with label hidden
        return prevState === LabelType.DEFAULT ? LabelType.SHORT : LabelType.HIDDEN;
      });
    }
  }, [duration, labelType]);

  const shortPuckFlag = !showFlightNumber ? 'short-puck-flag' : '';
  const mxMessageindicatorClass = hasMxMessage(data) ? 'mx-message-indicator-puck' : '';

  const handleMouseEnter = () => {
    setShowFlightActionMenu(true);
  };

  const flightActionMenuContainer = (
    <div className={`flight-action-menu ${shortPuckFlag}`}>
      <FlightActionMenu
        flightLeg={data}
        openDetailPane={openDetailPane}
        isPaneOpen={isPaneOpen}
        summaryPanelMode={summaryPanelMode}
        handleChangeActivityKey={handleChangeActivityKey}
        overLayHandler={(e) => setShowOverLay(e)}
        getFlightLineForFlightPuck={getFlightLineForFlightPuck}
        puckType={data.puckType}
      />
    </div>
  );

  let selectLabel = '';
  if (isSelected) {
    selectLabel = 'selected-puck';
    if (isSelected && enableEnhancedScalingFlag) {
      selectLabel = 'enhanced-scaling-selected-puck';
    }
  }

  const handleClick = async () => {
    clearFocusedFlightLeg();
    if (isSummaryPanelAvailable && !isSelected) {
      const newFlightDetails = {
        data: data,
        isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
        isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
      };
      updateSelectedFlightDetails(newFlightDetails);
    }
  };

  /**
   * @description Determine the CSS class name(s) to use for the OTS ground puck.
   */
  const getOtsClassName = () => {
    let otsPuckClassName = `ground-puck ots-ground-puck ots-${currentTheme}`;

    // Default if nothing to check.
    if (!label) return otsPuckClassName;

    if (label.includes('ETR')) {
      // Add the mismatch class name if arrival and departure stations don't match,
      // and only if it's not a ground puck for a canceled puck
      otsPuckClassName += ' ots-puck-etr';
    } else if (state == OutOfServiceState.COMPLETED_ETR) {
      otsPuckClassName += ' ots-puck-comp-etr';
    } else if (state == OutOfServiceState.COMPLETED) {
      otsPuckClassName += ' ots-puck-comp';
    } else {
      otsPuckClassName += ' ots-puck-adv';
    }

    return otsPuckClassName;
  };

  /**
   * @description The CSS class name(s) to use for the Block ground puck.
   */
  const blockClassName = `ground-puck block-ground-puck block-${currentTheme}`;

  /**
   * Print details of out of service puck to console
   */

  return (
    <div
      className={`flightpuck-container ${selectLabel} ${mxMessageindicatorClass} ${shortPuckFlag} ${
        showOverLay ? 'visible-overlay' : ''
      }`}
      data-cy="flightpuck-container"
    >
      <div
        className={getFlightPuckClassNames(
          [isBlockPuck ? blockClassName : getOtsClassName()],
          data,
          isSelected,
          isPaneOpen,
          false,
          false,
          false,
        )}
        style={puckStyle}
        ref={groundEventPuckRef}
        data-cy={`ground-puck-${groundEventKey}`}
        onMouseEnter={handleMouseEnter}
        onClick={handleClick}
      >
        {hoursArray.length > 0 ? (
          hoursArray.map((item, index) => (
            <div
              key={`ground-puck-label-${groundEventKey}-${index}`}
              className={isBlockPuck ? blockClassName : getOtsClassName()}
              data-cy={`ground-puck-label-${index}`}
              style={{
                width: item > 0 ? `calc(${item * 60} * var(--time-scale-minute))` : null,
                overflow: 'hidden',
              }}
            >
              {label}
              {airline !== getAirlineName(AirlineFilter.OO) && (
                <div className="ground-puck-access-menu">{showFlightActionMenu && flightActionMenuContainer}</div>
              )}
            </div>
          ))
        ) : labelType === LabelType.HIDDEN ? (
          <>
            {airline !== getAirlineName(AirlineFilter.OO) && (
              <div className="ground-puck-access-menu-no-label">
                {showFlightActionMenu && flightActionMenuContainer}
              </div>
            )}
          </>
        ) : (
          <>
            {label}
            {airline !== getAirlineName(AirlineFilter.OO) && (
              <div className="ground-puck-access-menu">{showFlightActionMenu && flightActionMenuContainer}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(OutOfServiceGroundPuck, (prevProps, nextProps) => {
  return jsonEqual(prevProps.data, nextProps.data) && prevProps.isSelected === nextProps.isSelected;
});

OutOfServiceGroundPuck.propTypes = {
  data: PropTypes.shape({
    groundEventKey: PropTypes.number.isRequired,
    groundEventStation: PropTypes.string.isRequired,
    groundEventType: PropTypes.oneOf(Object.keys(GroundEventType).map((k) => GroundEventType[k])),
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    state: PropTypes.oneOf(Object.keys(OutOfServiceState).map((k) => OutOfServiceState[k])),
    airline: PropTypes.string,
    aircraft: PropTypes.string,
    puckType: PropTypes.oneOf(Object.keys(PuckType).map((k) => PuckType[k])),
  }),
  openDetailPane: PropTypes.func.isRequired,
  isPaneOpen: PropTypes.bool.isRequired,
  summaryPanelMode: PropTypes.string.isRequired,
  handleChangeActivityKey: PropTypes.func.isRequired,
  getFlightLineForFlightPuck: PropTypes.func.isRequired,
  airline: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
};
