import React, { useState, useEffect } from 'react';
import './ManageViewsDropdown.css';
import { Tooltip } from '@material-ui/core';
import { ReactComponent as ColumnIcon } from '../../../../assets/images/columns-icon.svg';
import { ReactComponent as RowsIcon } from '../../../../assets/images/rows-icon.svg';
import { ReactComponent as CascadeIcon } from '../../../../assets/images/cascade-icon.svg';
import { ReactComponent as GridIcon } from '../../../../assets/images/grid-icon.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/blue-check-mark.svg';
import SingleSelectDropdown from '../../../Shared/Inputs/SingleSelectDropdown/SingleSelectDropdown';
import { ReactComponent as ManageViewsIcon } from '../../../../assets/images/manage-views-icon.svg';
import { ReactComponent as ManageViewsDisabledIcon } from '../../../../assets/images/disabled-layout-icon.svg';
import {
  useWindowManagerDispatch,
  useWindowManagerStore,
} from '../../../../hooks/useWindowManagerStore/useWindowManagerStore';

export const CheckOverflow = ({ content }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  useEffect(() => {
    setIsOverflow(content.length > 58);
  }, [content]);

  const truncatedContent = content.length > 58 ? content.slice(0, 58) + '...' : content;

  return (
    <Tooltip
      title={content}
      placement="bottom"
      disableHoverListener={!isOverflow}
      PopperProps={{ style: { zIndex: 1700 } }}
    >
      <div className="static-text">{truncatedContent}</div>
    </Tooltip>
  );
};

const createViewOption = (key, content, onClickCallback, showCheckIcon = false) => ({
  key,
  name: (
    <button className={`available-view ${key}`} onClick={onClickCallback}>
      {showCheckIcon && (
        <div className="check-icon">
          <CheckIcon />
        </div>
      )}
      <CheckOverflow content={content} />
    </button>
  ),
});

const availableViews = (viewWindows, viewOrder, onViewClick, searchFilterTitle) =>
  viewWindows.map((viewWindow, index) => {
    const { id, viewTitle } = viewWindow;
    const isActive = id === viewOrder[0];
    return createViewOption(
      `view${index + 1}`,
      `${index + 1}. ${searchFilterTitle?.[id] ?? viewTitle}`,
      () => onViewClick(id),
      isActive,
    );
  });

function organizeViews(key, IconComponent, action) {
  return {
    key,
    name: (
      <div className="layout-option">
        <div className="layout-option-icon">
          <IconComponent />
        </div>{' '}
        {key.charAt(0).toUpperCase() + key.slice(1)}{' '}
      </div>
    ),
    action,
  };
}

const ManageViewsDropdown = ({ searchFilterTitle, onClickHandler = () => {}, disabled = false }) => {
  const [selectedOrganizeView, setSelectedOrganizeView] = useState('');
  const windowManagerStore = useWindowManagerStore();
  const { viewWindows, viewOrder, minimizedViews } = windowManagerStore;
  const {
    setActiveView,
    arrangeViewsCascade,
    removeMinimizedView,
    arrangeViewsGrid,
    arrnageViewColumns,
    arrnageViewRows,
  } = useWindowManagerDispatch();
  const onClickManageViewDropdown = () => {
    onClickHandler();
  };

  // Call option action and clear action so users can reselect
  useEffect(() => {
    if (selectedOrganizeView) {
      const selectedViewOption = viewOptions.find((option) => option.key === selectedOrganizeView);
      if (selectedViewOption?.action) {
        selectedViewOption.action();
      }
      setSelectedOrganizeView('');
    }
  }, [selectedOrganizeView]);

  const screenOrientation = window.screen?.orientation?.type;

  const viewOptions = [
    organizeViews('columns', ColumnIcon, () => arrnageViewColumns()),
    organizeViews('rows', RowsIcon, () => arrnageViewRows()),
    organizeViews('cascade', CascadeIcon, () => arrangeViewsCascade()),
    organizeViews('grid', GridIcon, () => arrangeViewsGrid({ screenOrientation: screenOrientation })),
  ];

  const customIcon = () => (disabled ? <ManageViewsDisabledIcon /> : <ManageViewsIcon />);

  const handleViewClick = (viewId) => {
    setActiveView(viewId);
    if (minimizedViews.includes(viewId)) {
      removeMinimizedView(viewId);
    }
  };

  return (
    <div
      className={`manage-views-container ${disabled ? 'disabled' : ''}`}
      data-cy="manage-views-container"
      onClick={onClickManageViewDropdown}
    >
      <SingleSelectDropdown
        customIcon={customIcon()}
        displayList={viewOptions}
        className="manage-views-dropdown"
        dataCyTag="manage-views-dropdown"
        divider={<div className="view-layout-divider"> {true}</div>}
        displayList2={availableViews(viewWindows, viewOrder, handleViewClick, searchFilterTitle)}
        isDisabled={disabled}
        onChange={(e) => setSelectedOrganizeView(e.target.value)}
        onKeyDown={(e) => setSelectedOrganizeView(e.target.value)}
        displayText={selectedOrganizeView}
      ></SingleSelectDropdown>
    </div>
  );
};

export default ManageViewsDropdown;
