import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { ReactComponent as SortIcon } from '../../../assets/images/Sort.svg';
import DynamicCheckbox from '../../Shared/Inputs/DynamicCheckbox/DynamicCheckbox';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSortFilterDispatch, useSortFilterStore } from '../../../hooks/useSortFilterStore/useSortFilterStore';
import { SortActions } from '../../../redux/actionTypes';

const useStyles = makeStyles((_theme) => ({
  popover: {
    marginTop: '.8rem',
  },
}));

const GanttSortMenu = ({ filterButtonStyle, flightsStyle }) => {
  const styleClass = useStyles();
  const [sortMenu, setSortMenu] = useState(null);
  const { updateSortFilter } = useSortFilterDispatch();
  const { sortFilter } = useSortFilterStore();
  const onCloseSortFlight = () => {
    setSortMenu(null);
  };

  const handleSortFlightSelect = (event) => {
    setSortMenu(sortMenu ? null : event.currentTarget);
  };

  const saveSortAction = (sortAction) => {
    updateSortFilter(sortAction);
  };

  const onChangeRadioButton = (event) => {
    switch (event) {
      case 'Default':
        saveSortAction(SortActions.DEFAULT_PRIORITY);
        break;
      case 'ETD':
        saveSortAction(SortActions.ETD_PRIORITY);
        break;
      case 'ETA':
        saveSortAction(SortActions.ETA_PRIORITY);
        break;
      default:
        break;
    }
    setSortMenu(null);
  };

  return (
    <>
      <button
        data-cy="sort-flights-button"
        className={`nav-filter-button flight-nav-bar-item ${filterButtonStyle}`}
        onClick={handleSortFlightSelect}
      >
        <SortIcon data-cy={`flight-sort-icon`} className={`nav-icon ${flightsStyle}`} />
      </button>
      <Popover
        id={sortMenu ? 'sort-flights-popover' : undefined}
        anchorEl={sortMenu}
        open={!!sortMenu}
        onClose={onCloseSortFlight}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        className={styleClass.popover}
        data-cy="sort-flights-popover"
      >
        <DynamicCheckbox
          dataCyTag="default-radio"
          label="Default"
          type={'radio'}
          customCSSTag={'hide-canceled-flights'}
          checked={sortFilter === SortActions.DEFAULT_PRIORITY}
          onChange={onChangeRadioButton}
          value="default-radio"
        />
        <DynamicCheckbox
          dataCyTag="etd-radio"
          label="ETD"
          type={'radio'}
          customCSSTag={'hide-canceled-flights'}
          checked={sortFilter === SortActions.ETD_PRIORITY}
          onChange={onChangeRadioButton}
          value="etd-radio"
        />
        <DynamicCheckbox
          dataCyTag="eta-radio"
          label="ETA"
          type={'radio'}
          customCSSTag={'hide-canceled-flights'}
          checked={sortFilter === SortActions.ETA_PRIORITY}
          onChange={onChangeRadioButton}
          value="eta-radio"
        />
      </Popover>
    </>
  );
};

GanttSortMenu.propTypes = {
  filterButtonStyle: PropTypes.string.isRequired,
  flightsStyle: PropTypes.string.isRequired,
};

export default GanttSortMenu;
