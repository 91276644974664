import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Shared/Modal/Modal';
import Button from '../../Shared/Button/Button';
import './ScalingModal.css';
import { KeyCodes } from '../../../lib/constants';
import Counter from '../../Shared/Counter/Counter';
import { useUserPreferencesFunctions } from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import {
  useViewConfigurationDispatch,
  useViewConfigurationStore,
} from '../../../hooks/useViewConfigurationStore/useViewConfigurationStore';

const defaultScaling = { hoursBefore: 4, hoursAfter: 32, numberOfAircrafts: 45 };

/**
 * ScalingModal component is used to display the modal for the scalability feature.
 * @param {funcation}  onClose - Function to close the modal.
 * @param {boolean}  showModal - Boolean value to show/hide the modal
 * @returns  Modal component with the body and footer buttons.
 */
const ScalingModal = ({ onClose, showModal = false }) => {
  const { updateGanttViewScalingPreference } = useUserPreferencesFunctions();
  const { updateViewConfiguration } = useViewConfigurationDispatch();

  const viewConfigurationData = useViewConfigurationStore();

  const [scaling, setScaling] = useState(defaultScaling);
  const [isDisabled, setIsDisabled] = useState({
    hoursBefore: { minDisable: false, maxDisable: false },
    hoursAfter: { minDisable: false, maxDisable: false },
    numberOfAircrafts: { minDisable: false, maxDisable: false },
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const inputRefHourBefore = useRef(null);
  const inputRefHourAfter = useRef(null);
  const inputRefnumberOfAircrafts = useRef(null);

  useEffect(() => {
    ['numberOfAircrafts', 'hoursBefore', 'hoursAfter'].forEach(handleDisable);
  }, [scaling]);

  // to set button disable state
  useEffect(() => {
    setIsButtonDisabled(
      viewConfigurationData.hoursBefore === scaling.hoursBefore &&
        viewConfigurationData.hoursAfter === scaling.hoursAfter &&
        viewConfigurationData.numberOfAircrafts === scaling.numberOfAircrafts,
    );
  }, [scaling, viewConfigurationData]);

  useEffect(() => {
    if (!showModal) {
      const { hoursBefore, hoursAfter, numberOfAircrafts } = viewConfigurationData;
      setScaling({ hoursBefore, hoursAfter, numberOfAircrafts });
    }
  }, [showModal]);

  useEffect(() => {
    // set initial scaling
    const scalingData = viewConfigurationData;
    setScaling(scalingData);
  }, [viewConfigurationData]);

  const handleDisable = useCallback(
    (key) => {
      setIsDisabled((values) => ({ ...values, [key]: setIsDisabledValue(key) }));
    },
    [scaling],
  );

  const setIsDisabledValue = (key) => {
    const constraints = {
      numberOfAircrafts: { min: 5, max: 196 },
      hoursBefore: { max: 96 },
      hoursAfter: { max: 96 },
    };
    const { min = 0, max } = constraints[key]; // Default min to 0 if not specified
    const value = scaling[key];

    return {
      minDisable: value <= min,
      maxDisable: value >= max,
    };
  };
  // Increment the values of the counter
  const handleIncrement = (key, inputRef) => {
    const limits = { numberOfAircrafts: 196, hoursBefore: 96, hoursAfter: 96 };
    const increment = key === 'numberOfAircrafts' ? 5 : 1;

    setScaling((prevState) => {
      const currentValue = prevState[key];
      if (currentValue < limits[key]) {
        return { ...prevState, [key]: currentValue + increment };
      }
      return prevState; // Return current state if limit is reached
    });
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, 100);
  };
  const resetValue = () => {
    setScaling(defaultScaling);
  };

  /**CLEAR Button Key Down */
  const handleClearButtonKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ENTER) {
      resetValue();
    }
  };

  // Decrement the values of the counter
  const handleDecrement = (key, inputRef) => {
    const minimums = { numberOfAircrafts: 5, hoursBefore: 0, hoursAfter: 0 };
    const decrement = key === 'numberOfAircrafts' ? 5 : 1;
    setScaling((prevState) => {
      const currentValue = prevState[key];
      if (currentValue > minimums[key]) {
        return { ...prevState, [key]: currentValue - decrement };
      }
      return prevState; // Return current state if minimum is reached
    });
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleInputChange = (event) => {
    if (event.target.validity.valid) {
      const name = event.target.name;
      const value = parseInt(event.target.value, 10); // Ensure radix parameter is specified for clarity
      const constraints = {
        numberOfAircrafts: { min: 1, max: 200 },
        hoursBefore: { max: 96 },
        hoursAfter: { max: 96 },
      };

      const { min = 0, max } = constraints[name]; // Default min to 0 if not specified
      if (value >= min && value <= max) {
        setScaling((values) => ({ ...values, [name]: value }));
      }
    }
  };

  const handleApplyButton = () => {
    const { hoursBefore, hoursAfter, numberOfAircrafts } = scaling;
    const selectedHours = parseInt(hoursBefore) + parseInt(hoursAfter);
    const newScaling = { hoursBefore, hoursAfter, numberOfAircrafts };
    // When difference of selectedHours is 0, set the scaling back to defaults for before and after
    if (selectedHours === 0) {
      newScaling.hoursBefore = defaultScaling.hoursBefore;
      newScaling.hoursAfter = defaultScaling.hoursAfter;
    }

    updateViewConfiguration({
      hoursBefore: newScaling.hoursBefore,
      hoursAfter: newScaling.hoursAfter,
      numberOfAircrafts: newScaling.numberOfAircrafts,
      puckSize: null,
    });

    updateGanttViewScalingPreference(newScaling.hoursBefore, newScaling.hoursAfter, newScaling.numberOfAircrafts);

    onClose();
  };

  const handleApplyButtonKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ENTER) {
      handleApplyButton();
    }
  };

  // Body of the modal with the counter components for hoursBefore, hoursAfter and numberOfAircrafts values
  const body = (
    <div className="Scaling-modal-row" data-cy="scaling-modal">
      <Counter
        label="Set Hours Before"
        value={scaling.hoursBefore}
        inputname="hoursBefore"
        onIncrement={() => handleIncrement('hoursBefore', inputRefHourBefore)}
        onDecrement={() => handleDecrement('hoursBefore', inputRefHourBefore)}
        dataCy="set-hours-before-scaling"
        onInputchange={handleInputChange}
        autoFocus={true}
        inputRef={inputRefHourBefore}
        isDisabledMinus={isDisabled.hoursBefore.minDisable}
        isDisabledPlus={isDisabled.hoursBefore.maxDisable}
      />
      <Counter
        label="Set Hours After"
        value={scaling.hoursAfter}
        inputname="hoursAfter"
        onIncrement={() => handleIncrement('hoursAfter', inputRefHourAfter)}
        onDecrement={() => handleDecrement('hoursAfter', inputRefHourAfter)}
        dataCy="set-hours-after-scaling"
        onInputchange={handleInputChange}
        autoFocus={false}
        inputRef={inputRefHourAfter}
        isDisabledMinus={isDisabled.hoursAfter.minDisable}
        isDisabledPlus={isDisabled.hoursAfter.maxDisable}
      />
      <Counter
        label="Number of Aircraft"
        value={scaling.numberOfAircrafts}
        inputname="numberOfAircrafts"
        onIncrement={() => handleIncrement('numberOfAircrafts', inputRefnumberOfAircrafts)}
        onDecrement={() => handleDecrement('numberOfAircrafts', inputRefnumberOfAircrafts)}
        dataCy="aircraft-number-scaling"
        onInputchange={handleInputChange}
        autoFocus={false}
        inputRef={inputRefnumberOfAircrafts}
        isDisabledMinus={isDisabled.numberOfAircrafts.minDisable}
        isDisabledPlus={isDisabled.numberOfAircrafts.maxDisable}
      />
    </div>
  );

  // Footer buttons for the modal component with Apply and Clear buttons
  const formButtons = [
    <Button
      key="apply"
      variant="primary"
      className="scaling-modal-footer-button apply"
      tabIndex="0"
      isDisabled={isButtonDisabled}
      onClick={handleApplyButton}
      onKeyDown={handleApplyButtonKeyDown}
      data-cy="nav-scaling-apply-btn"
    >
      Apply
    </Button>,
    <Button
      key="clear"
      variant="secondary"
      className="scaling-modal-footer-button clear"
      tabIndex="0"
      onClick={resetValue}
      onKeyDown={(event) => handleClearButtonKeyDown(event)}
      data-cy="nav-scaling-clear-btn"
    >
      Clear
    </Button>,
  ];

  // Modal component with the body and footer buttons for the scalability feature modal
  return (
    <Modal
      focusId="focusId"
      title="Scaling"
      body={body}
      show={showModal}
      size="thin"
      onHide={onClose}
      customCSSTag="thin"
      footerButtons={formButtons}
      bottomLine={false}
    />
  );
};

ScalingModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
};
export default ScalingModal;
