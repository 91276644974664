import { useDispatch } from 'react-redux';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';
import { useCallback } from 'react';
import { FlightPuckRefAction } from '../../redux/actionTypes';
import { createSelector } from 'reselect';

/**
 * Custom hook for retrieving the flight puck ref for the given flight leg key
 * @param {Number} flightLegKey
 * @returns {Element} the flight puck ref
 */
export const useFlightPuckRefStore = (flightLegKey) => {
  return useSelector(
    createSelector(
      (state) => state.flightPuckRefReducer,
      (refs) => refs?.[flightLegKey],
    ),
  );
};

/**
 * Custom hook for dispatching actions to hide cancelled flights
 * @returns object of dispatch actions to interact with the hide store
 */
export const useFlightPuckRefsDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Adds a flight puck ref to the store
   * @param {Number} flightLegKey
   * @param {Element} ref - ref element to the flight puck
   */
  const addFlightPuckRef = useCallback(
    (flightLegKey, ref) => {
      dispatch({
        type: FlightPuckRefAction.ADD_FLIGHT_PUCK_REF,
        payload: {
          flightLegKey,
          ref,
        },
      });
    },
    [dispatch],
  );

  /**
   * Removes a flight puck ref from the store
   * @param {Number} flightLegKey
   */
  const removeFlightPuckRef = useCallback(() => {
    dispatch({
      type: FlightPuckRefAction.REMOVE_FLIGHT_PUCK_REF,
    });
  }, [dispatch]);

  return { addFlightPuckRef, removeFlightPuckRef };
};
