import React from 'react';
import PropTypes from 'prop-types';
import CrewTable from '../CrewTable/CrewTable';
import { Grid } from '@material-ui/core';
import { reArrangeDeadheadsCrewData } from '../../../summaryPanelUtils';
import './DeadheadCrewTables.css';

const DeadheadCrewTables = ({ flightCrew, nextFlightCrew }) => {
  return (
    <Grid container spacing={2} className="deadheads-table">
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          flightInfo={flightCrew.flight}
          crewInfoList={reArrangeDeadheadsCrewData(flightCrew.deadHeadCrewTrips).table1}
          columnNames={['', '', 'TRIP']}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          crewInfoList={reArrangeDeadheadsCrewData(flightCrew.deadHeadCrewTrips).table2}
          columnNames={['', '', 'TRIP']}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          flightInfo={nextFlightCrew?.flight}
          crewInfoList={reArrangeDeadheadsCrewData(nextFlightCrew?.deadHeadCrewTrips).table1}
          columnNames={['', '', 'TRIP']}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          crewInfoList={reArrangeDeadheadsCrewData(nextFlightCrew?.deadHeadCrewTrips).table2}
          columnNames={['', '', 'TRIP']}
        />
      </Grid>
    </Grid>
  );
};

DeadheadCrewTables.propTypes = {
  flightCrew: PropTypes.object.isRequired,
  nextFlightCrew: PropTypes.object,
};

export default DeadheadCrewTables;
