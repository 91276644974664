import ModernFlightPuck from './ModernFlightPuck/ModernFlightPuck';
import ClassicFlightPuck from './ClassicFlightPuck/ClassicFlightPuck';
import { useThemeContext } from '../../../contexts/ThemeContext/ThemeContext';
import { ThemeMode } from '../../../lib/constants';

/**
 * Renders the flight puck based on user's theme
 * @param {*} props
 * @returns
 */
export default function FlightPuck(props) {
  const { currentTheme } = useThemeContext();
  switch (currentTheme) {
    case ThemeMode.CLASSIC:
    case ThemeMode.HIGH_CONTRAST:
      return <ClassicFlightPuck {...props} />;
    default:
      return <ModernFlightPuck {...props} />;
  }
}
