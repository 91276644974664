import PropTypes from 'prop-types';
import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import StationPuck from '../../StationPuck/StationPuck';
import { onDropMoveFlights } from '../../../../lib/swapUtil';
import './SwapStationPuck.css';
import { useMultiSelectedFlightGroupDispatch } from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders the station puck based on user's theme after swapMode is on
 * @param {*} props
 * @returns
 */
export default function SwapStationPuck(props) {
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();
  const { updateMoveFlightLeg } = useSwapModeDispatch();
  const dragItem = useDragItemStore();
  const { data, flightLegGroup, disableSwapSubmit } = props;

  const { aircraft, index } = data;

  const [dropRef] = useDropItem({
    dropItemKey: `${aircraft}-${index}`,
    data,
    onDrop: () => {
      if (disableSwapSubmit) {
        return;
      }
      onDropMoveFlights(dragItem, flightLegGroup, aircraft, updateMoveFlightLeg, removeAllMultiSelectedFlightGroup);
    },
  });

  return (
    <div ref={dropRef} className="swap-station-puck">
      <StationPuck {...props} />
    </div>
  );
}

SwapStationPuck.propTypes = {
  data: PropTypes.object.isRequired,
  disableSwapSubmit: PropTypes.bool.isRequired,
  flightLegGroup: PropTypes.object.isRequired,
};
