import { ViewConfigurationAction } from '../../redux/actionTypes';
import { useSelector, useDispatch } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Selector for the Config store
 */
const selectViewConfigurationMode = (state) => state.viewConfigurationReducer;

/**
 * Custom hook for retrieving the cross hair store
 * @returns selector hook for retrieving crosshair store
 */
export const useViewConfigurationStore = () => {
  return useSelector(selectViewConfigurationMode);
};

/**
 * Custom hook for dispatching actions to update crosshair flags
 */

export const useViewConfigurationDispatch = () => {
  const dispatch = useDispatch();

  // callback to update the config store
  const updateViewConfiguration = (payload) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_VIEWCONFIGURATION,
      payload: { ...payload },
    });
  };

  return {
    updateViewConfiguration,
  };
};
