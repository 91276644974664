import PropTypes from 'prop-types';
import { useDragItem } from '../../../../hooks/useDragItem/useDragItem';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import FlightPuck from '../../FlightPuck/FlightPuck';
import { getFlightPuckDragData, mapFlightDataToOutputWithKey, getCanDragOrDrop } from '../../../../lib/swapUtil';
import { useMemo } from 'react';
import {
  useMultiSelectedFlightGroupStore,
  useMultiSelectedFlightGroupDispatch,
} from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders the flight puck based on user's theme
 * @param {*} props
 * @returns
 */
export default function SwapFlightPuck(props) {
  const { getFlightLineForFlightPuck, data, ctrlKeyOverride = false, flightLegGroup, disableSwapSubmit } = props;
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();

  const canDragOrDrop = useMemo(() => {
    return getCanDragOrDrop(data);
  }, [data]);

  const [dragItemRef] = useDragItem({
    dragItemKey: `swap-drag-item-${data.flightLegKey}`,
    getDragData: (ctrlKey) => getFlightPuckDragData(ctrlKey, data, getFlightLineForFlightPuck),
    canDrag: canDragOrDrop,
    ctrlKeyOverride,
  });

  const swapModeDispatch = useSwapModeDispatch();
  const dragItem = useDragItemStore();

  const [dropRef] = useDropItem({
    dropItemKey: `swap-drop-item-${data.flightLegKey}`,
    data,
    onDrop: () => {
      if (disableSwapSubmit) {
        return;
      }
      // by default we are assuming single puck -> single puck
      var dragFlights = dragItem.data;
      var dragFlight = dragFlights[0];
      var dropFlights = getFlightPuckDragData(dragItem.ctrlKey, data, getFlightLineForFlightPuck);
      let draggedPuckIsInMultiFlightLeg =
        flightLegGroup[dragFlight.aircraft] !== undefined &&
        flightLegGroup[dragFlight.aircraft].some((x) => x.flightLegKey === dragFlight.flightLegKey);
      let droppedPuckIsInMultiFlightLeg =
        flightLegGroup[data.aircraft] !== undefined &&
        flightLegGroup[data.aircraft].some((x) => x.flightLegKey === data.flightLegKey);

      // flight group -> single puck
      if (draggedPuckIsInMultiFlightLeg && !droppedPuckIsInMultiFlightLeg) {
        dragFlights = [...flightLegGroup[dragFlight.aircraft]];
        dropFlights = [data];
      }
      // single puck -> flight group
      else if (!draggedPuckIsInMultiFlightLeg && droppedPuckIsInMultiFlightLeg) {
        dragFlights = dragItem.ctrlKey ? dragFlights : [dragFlight];
        dropFlights = [...flightLegGroup[data.aircraft]];
      }

      // flight group -> flight group
      else if (draggedPuckIsInMultiFlightLeg && droppedPuckIsInMultiFlightLeg) {
        dragFlights = [...flightLegGroup[dragFlight.aircraft]];
        dropFlights = [...flightLegGroup[data.aircraft]];
      }

      swapModeDispatch.swapFlightLine({
        flightData: [
          [...dragFlights.map((f) => mapFlightDataToOutputWithKey(f))],
          dropFlights.map((f) => mapFlightDataToOutputWithKey(f)),
        ],
      });

      if (Object.keys(flightLegGroup).length > 0) {
        removeAllMultiSelectedFlightGroup();
      }
    },
    canDrop: canDragOrDrop,
  });

  const attachRefs = (e) => {
    dropRef.current = e;
    dragItemRef.current = e;
  };

  return (
    <div className={props?.customCSSTag} ref={attachRefs}>
      <FlightPuck {...props} />
    </div>
  );
}

SwapFlightPuck.propTypes = {
  getFlightLineForFlightPuck: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  ctrlKeyOverride: PropTypes.bool,
  disableSwapSubmit: PropTypes.bool.isRequired,
  flightLegGroup: PropTypes.object,
};
