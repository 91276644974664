import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './EarlyDelayIndicatorLine.css';
import { getPuckArrivalDepartureLength } from '../GanttChart/ganttHelpers';
import { datetimeIsBefore, getDatetimeUtcNowString } from '../../../lib/dateTimeUtils';
import { getMargin } from './EarlyDelayIndicatorLineUtils';

/* flight puck departure indicators delay or early
 * returns departure indicator div  */
const getDepartureIndicator = (userPreferencesState, puckArrDepLengths, data, arrtime, nowtime) => {
  if (puckArrDepLengths.depIndicatorLength !== 0) {
    let className = puckArrDepLengths.depIndicatorLength > 0 ? 'delay' : 'early';
    // check current flight phase in for dark line
    if (datetimeIsBefore(arrtime, nowtime)) {
      className = `darkline-${className}`;
    }

    let width = Math.abs(puckArrDepLengths.depIndicatorLength);
    let bottom = getMargin(userPreferencesState?.puckSize?.puckSize);

    return (
      <div
        className={`departure-indicator ${className}`}
        data-cy={`departure-indicator-${data.flightLegKey}`}
        style={{ width: `calc(${width} * var(--time-scale-minute))`, marginBottom: bottom }}
      />
    );
  }
};

/* flight puck arrival indicators delay or early
 * returns arrival indicator div  */
const getArrivalIndicator = (userPreferencesState, puckArrDepLengths, selectLabel, data, arrtime, nowtime) => {
  if (puckArrDepLengths.arrIndicatorLength !== 0) {
    let className = puckArrDepLengths.arrIndicatorLength > 0 ? 'delay' : 'early';
    // check current flight phase in for dark line
    if (datetimeIsBefore(arrtime, nowtime)) {
      className = `darkline-${className}`;
    }

    let width = Math.abs(puckArrDepLengths.arrIndicatorLength);
    let top = getMargin(userPreferencesState?.puckSize?.puckSize);

    return (
      <div
        className={`arrival-indicator ${className} ${selectLabel}`}
        data-cy={`arrival-indicator-${data.flightLegKey}`}
        style={{ width: `calc(${width} * var(--time-scale-minute))`, marginTop: top }}
      />
    );
  }
};

/**
 * EarlyDelayIndicatorLine component wraps pucks to add lines for early/late delays/arrivals
 * @param {any} children - child elements this component wraps
 * @param {data} data - flight leg data
 * @returns Child component wrapped in EarlyDelayIndicatorLine component
 */
const EarlyDelayIndicator = ({ children, data, userPreferencesState, isSelected }) => {
  //Get puck's display statistics
  const puckArrDepLengths = useMemo(() => getPuckArrivalDepartureLength(data), [data]);
  const nowtime = getDatetimeUtcNowString();
  const arrtime = data.arrival;

  const selectLabel = isSelected ? 'selected-puck' : '';

  const departureIndicator = useMemo(
    () => getDepartureIndicator(userPreferencesState, puckArrDepLengths, data, arrtime, nowtime),
    [userPreferencesState, puckArrDepLengths, selectLabel, data, arrtime, nowtime],
  );

  const arrivalIndicator = useMemo(
    () => getArrivalIndicator(userPreferencesState, puckArrDepLengths, selectLabel, data, arrtime, nowtime),
    [userPreferencesState, puckArrDepLengths, selectLabel, data, arrtime, nowtime],
  );

  return (
    <>
      {departureIndicator}
      {children}
      {arrivalIndicator}
    </>
  );
};

EarlyDelayIndicator.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  userPreferencesState: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default EarlyDelayIndicator;
