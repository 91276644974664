import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlightActionMenu from '../../FlightActionMenu/FlightActionMenu';
import './ClassicFlightPuck.css';
import { IrropsCode } from '../../../../lib/constants';
import { displayFlightNumber } from '../../../../lib/displayUtils';
import { jsonEqual } from '../../../../lib/utils';
import { getFlightPuckClassNames, getPuckRenderStat } from '../../GanttChart/ganttHelpers';
import { useFlightPuckRefsDispatch } from '../../../../hooks/useFlightPuckRefStore/useFlightPuckRefStore';
import { useFlightLegStatus } from '../../../../hooks/useFlightLeg/useFlightLeg';

const FlightGroupOverlay = ({ selectedPuckThroughDoubleClick }) => (
  <div
    className={`group-puck-container${selectedPuckThroughDoubleClick ? ' selected-group-puck' : ''}`}
    data-cy="group-puck-container"
  />
);

FlightGroupOverlay.propTypes = {
  selectedPuckThroughDoubleClick: PropTypes.bool.isRequired,
};

const getSelectedLabel = (isSelected, enableEnhancedScalingFlag) => {
  let selectLabel = '';
  if (isSelected) {
    selectLabel = 'selected-puck';
    if (enableEnhancedScalingFlag) {
      selectLabel = 'enhanced-scaling-selected-puck';
    }
  }
  return selectLabel;
};

/**
 * Classic Flight Puck component renders a puck to be displayed in the gantt chart
 * @param {Object} getFlightLineForFlightPuck - flight pucks along the line
 * @param {Object} data - a flight puck data object to be rendered
 * @param {function} openDetailPane - callback to open the detail pane
 * @param {bool} isPaneOpen - whether the detail panel is open
 * @param {string} summaryPanelMode - the mode of the summary panel
 */
const ClassicFlightPuck = ({
  getFlightLineForFlightPuck,
  data,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
  shortTurnFlag,
  equipmentChangeFlag,
  enableEnhancedScalingFlag,
  isSelected,
  handleClick,
  handleOnDoubleClick,
  isMultiGroupSelected,
  isFocused,
}) => {
  //Get puck's display statistics
  const puckRenderStat = useMemo(() => getPuckRenderStat(data), [data]);
  const { hasMxMessage } = useFlightLegStatus(data);
  const [showFlightNumber, setShowFlightNumber] = useState(true);
  const flightNumberRef = useRef(null);
  const [showFlightActionMenu, setShowFlightActionMenu] = useState(false);
  const [showOverLay, setShowOverLay] = useState(false);
  const { addFlightPuckRef } = useFlightPuckRefsDispatch();
  const flightPuckRef = useRef(null);

  const onClick = useCallback(
    (e) => {
      handleClick(e, data);
    },
    [data, handleClick],
  );

  const onDoubleClick = useCallback(() => {
    handleOnDoubleClick(data, getFlightLineForFlightPuck);
  }, [data, handleOnDoubleClick, getFlightLineForFlightPuck]);

  const handleMouseEnter = useCallback(() => {
    setShowFlightActionMenu(true);
  }, []);

  const puckStyle = {
    width: `calc(${puckRenderStat.puckDuration} * var(--time-scale-minute))`,
  };

  const shortPuckFlag = !showFlightNumber ? 'short-puck-flag' : '';

  /**  flight action menu render */
  const flightActionMenuContainer = (
    <div className={`flight-action-menu ${shortPuckFlag}`}>
      <FlightActionMenu
        flightLeg={data}
        openDetailPane={openDetailPane}
        isPaneOpen={isPaneOpen}
        summaryPanelMode={summaryPanelMode}
        handleChangeActivityKey={handleChangeActivityKey}
        overLayHandler={(e) => setShowOverLay(e)}
        getFlightLineForFlightPuck={getFlightLineForFlightPuck}
      />
    </div>
  );

  useEffect(() => {
    // hide flight number if there's text overflow
    const timerRef = setTimeout(() => {
      const element = flightNumberRef?.current;
      if (element && element.scrollWidth > element.clientWidth) {
        setShowFlightNumber(false);
      } else {
        setShowFlightNumber(true);
      }
    }, 1000);
    return () => clearTimeout(timerRef);
  }, []);

  const mxMessageindicatorClass = hasMxMessage(data) ? 'mx-message-indicator-puck' : '';

  let selectLabel = useMemo(
    () => getSelectedLabel(isSelected, enableEnhancedScalingFlag),
    [isSelected, enableEnhancedScalingFlag],
  );

  const selectedPuckThroughDoubleClick = isMultiGroupSelected;

  useEffect(() => {
    if (flightPuckRef?.current != null && isFocused) {
      addFlightPuckRef(data.flightLegKey, flightPuckRef.current);
    }
  }, [flightPuckRef, data.flightLegKey, isFocused]);

  const puckClassNames = useMemo(
    () =>
      getFlightPuckClassNames(
        ['classic-flight-puck'],
        data,
        isSelected,
        isPaneOpen,
        selectedPuckThroughDoubleClick,
        shortTurnFlag,
        equipmentChangeFlag,
      ),
    [data, isSelected, isPaneOpen, selectedPuckThroughDoubleClick, shortTurnFlag, equipmentChangeFlag],
  );

  return (
    <div
      className={`flightpuck-container ${selectLabel} ${mxMessageindicatorClass} ${shortPuckFlag} ${
        showOverLay ? 'visible-overlay' : ''
      }`}
      data-cy="flightpuck-container"
      ref={flightPuckRef}
    >
      <div
        className={puckClassNames}
        data-cy={`${data.irropsCode === IrropsCode.CANCELLED_FLIGHT ? 'canceled-' : ''}flight-puck-${
          data.flightLegKey
        }`}
        style={puckStyle}
        onMouseDown={onDoubleClick}
        onMouseEnter={handleMouseEnter}
        onClick={onClick}
      >
        {showFlightNumber && (
          <div className="flight-number" ref={flightNumberRef}>
            <span className="flightnumber-text">{displayFlightNumber(data)}</span>
          </div>
        )}
        {showFlightActionMenu && flightActionMenuContainer}
        {selectedPuckThroughDoubleClick ? (
          <FlightGroupOverlay selectedPuckThroughDoubleClick={selectedPuckThroughDoubleClick} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

ClassicFlightPuck.propTypes = {
  getFlightLineForFlightPuck: PropTypes.func.isRequired,
  data: PropTypes.shape({
    flightLegKey: PropTypes.number.isRequired,
    flightNumber: PropTypes.number.isRequired,
    orig: PropTypes.string.isRequired,
    dest: PropTypes.string.isRequired,
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    aircraft: PropTypes.string.isRequired,
  }).isRequired,
  openDetailPane: PropTypes.func.isRequired,
  isPaneOpen: PropTypes.bool.isRequired,
  summaryPanelMode: PropTypes.string.isRequired,
  handleChangeActivityKey: PropTypes.func.isRequired,
  shortTurnFlag: PropTypes.bool,
  equipmentChangeFlag: PropTypes.bool,
  enableEnhancedScalingFlag: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleOnDoubleClick: PropTypes.func.isRequired,
  isMultiGroupSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export default React.memo(ClassicFlightPuck, (prevProps, nextProps) => {
  return (
    jsonEqual(prevProps.data, nextProps.data) &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isMultiGroupSelected === nextProps.isMultiGroupSelected &&
    prevProps.isFocused === nextProps.isFocused &&
    prevProps.handleClick === nextProps.handleClick &&
    prevProps.isPaneOpen === nextProps.isPaneOpen &&
    prevProps.summaryPanelMode === nextProps.summaryPanelMode
  );
});
