import { useDispatch as reduxUseDispatch, useSelector as reduxUseSelector } from 'react-redux';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../lib/constants';
import { useViewId } from '../../contexts/ViewIdContext/ViewIdContext';

export const useDispatch = () => {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const dispatch = reduxUseDispatch();
  const { id } = useViewId() || { id: 0 };
  const customDispatch = (action) => dispatch({ ...action, id });
  return showMultipleViews ? customDispatch : dispatch;
};

export const useSelector = (selectorFn) => {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const { id } = useViewId() || { id: 0 };
  return showMultipleViews
    ? reduxUseSelector((state) => selectorFn(state?.viewWindowData.find((viewData) => viewData.id === id)) || {})
    : reduxUseSelector(selectorFn);
};
