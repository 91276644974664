import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { lightTheme } from '../../styles/themes/lightTheme';
import { darkTheme } from '../../styles/themes/darkTheme';
import { ThemeMode, Treatment } from '../../lib/constants';
import { useAppInsightsContext } from '../AppInsightsContext/AppInsightsContext';
import { getThemeDisplayText } from '../../lib/displayUtils';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { useUserPreferencesData, useUserPreferencesFunctions } from '../UserPreferencesContext/UserPreferencesContext';
import { useStylesQuery } from '../../react-query/useStylesQuery/useStylesQuery';

const DEFAULT_THEME = ThemeMode.CLASSIC;

const DARK_MODE_CLASS_NAME = 'modern-dark-theme';
const CLASSIC_MODE_CLASS_NAME = 'classic-theme';
const LIGHT_MODE_CLASS_NAME = 'modern-light-theme';
const HIGH_CONTRAST_MODE_CLASS_NAME = 'high-contrast-theme';

/**
 * Gets the MUI theme to apply
 * @param {string} theme - ThemeMode enum
 * @returns mui theme object
 */
const getMuiTheme = (theme) => {
  if (theme === ThemeMode.MODERN_LIGHT) {
    return createTheme(lightTheme);
  } else if (theme === ThemeMode.MODERN_DARK) {
    return createTheme(darkTheme);
  } else {
    // classic mode shares the same dark theme for now
    return createTheme(darkTheme);
  }
};

const ThemeContext = createContext(null);

/**
 * @description ThemesProvider provides implementation of MUI theme based on
 * user preference to select the light or dark theme mode in the application
 * */
const ThemesProvider = ({ children }) => {
  const { state: userPreferenceState, loading } = useUserPreferencesData();
  const { updateThemePreference } = useUserPreferencesFunctions();
  const { trackEvent } = useAppInsightsContext();

  // feature flagging theme ui changes until dark mode is complete
  const { isReady } = useContext(SplitContext);
  // @deprecated - (BA) useFeatureFlag hook instead of obsolete useTreatments
  const treatments = useTreatments([Treatment.DARK_MODE]);
  const isThemeChangeEnabled = isReady && treatments[Treatment.DARK_MODE]?.treatment === 'on';

  const [themeModePreference, setThemeModePreference] = useState(DEFAULT_THEME);
  const [enableStylesQuery, setEnableStylesQuery] = useState(false);
  const [businessRulesStyles, setBusinessRulesStyles] = useState(null);

  // material ui theme
  const muiTheme = useMemo(() => {
    return isThemeChangeEnabled ? getMuiTheme(themeModePreference) : getMuiTheme(DEFAULT_THEME);
  }, [themeModePreference, isThemeChangeEnabled]);

  //Fetch styles for station and aircraft labels for classic theme from Styles API
  const { isLoading: isLoadingStyleApiData, data: styleApiQueryData } = useStylesQuery(enableStylesQuery);

  // loads from user preference if it's there
  useEffect(() => {
    if (!loading && userPreferenceState?.theme?.theme) {
      setThemeModePreference(userPreferenceState.theme.theme);
    }
  }, [userPreferenceState, loading]);

  // updates root class name on theme change
  useEffect(() => {
    setEnableStylesQuery(false);
    document.documentElement.classList = '';
    if (isThemeChangeEnabled) {
      switch (themeModePreference) {
        case ThemeMode.MODERN_DARK:
          document.documentElement.classList.add(DARK_MODE_CLASS_NAME);
          break;
        case ThemeMode.MODERN_LIGHT:
          document.documentElement.classList.add(LIGHT_MODE_CLASS_NAME);
          break;
        case ThemeMode.HIGH_CONTRAST:
          document.documentElement.classList.add(CLASSIC_MODE_CLASS_NAME);
          document.documentElement.classList.add(HIGH_CONTRAST_MODE_CLASS_NAME);
          break;
        default:
          document.documentElement.classList.add(CLASSIC_MODE_CLASS_NAME);
          setEnableStylesQuery(true);
          break;
      }
    } else {
      document.documentElement.classList.add(CLASSIC_MODE_CLASS_NAME);
      setEnableStylesQuery(true);
    }
  }, [themeModePreference, isThemeChangeEnabled]);

  /**
   * Side-effect - styles api data
   */
  useEffect(() => {
    if (!isLoadingStyleApiData && styleApiQueryData && styleApiQueryData.styles) {
      setBusinessRulesStyles(styleApiQueryData.styles);
    }
  }, [isLoadingStyleApiData, styleApiQueryData]);

  /**
   * @description Updates the application theme
   * @param {string} theme - a ThemeMode enum e.g. ThemeMode.CLASSIC
   * */
  const updateTheme = useCallback(
    (theme) => {
      setThemeModePreference(theme);
      if (isThemeChangeEnabled) {
        trackEvent(`Application Theme Changed To ${getThemeDisplayText(theme)}`);
        updateThemePreference(theme);
      }
    },
    [isThemeChangeEnabled, trackEvent, updateThemePreference],
  );

  const contextData = useMemo(() => {
    return {
      updateTheme: updateTheme,
      currentTheme: themeModePreference,
      businessRulesStyles: businessRulesStyles,
    };
  }, [updateTheme, themeModePreference, businessRulesStyles]);

  return (
    <ThemeContext.Provider value={contextData}>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

/**
 * @description Custom hook to provide MUI theme
 * */
const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error(
      'useThemeContext must be used within a ThemesProvider. Wrap a parent component in <ThemesProvider> to fix this error.',
    );
  }
  return context;
};

export { ThemesProvider, useThemeContext };
