import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { TimeZones } from './constants';
/* Required by Dayjs to include these plugins when working with TimeZones */
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * @description Returns a station local date/time based on the UTC time, time zone to convert to, and string format provided.
 * @param {string} utcTime - UTC Time.
 * @param {string} timeZoneIdentifier - Required.  IANA time zone identifier.
 * @param {string} format - Optional: Format for the date/time to be returned as a string.  Defaults to: YYYY-MM-DD HH:mm
 * @returns {string}
 */
export const utcToStationLocalTime = (utcTime, timeZoneIdentifier, format = 'YYYY-MM-DD HH:mm') => {
  // validate input parameters.
  if (
    utcTime == null ||
    typeof utcTime === 'undefined' ||
    timeZoneIdentifier == null ||
    typeof timeZoneIdentifier === 'undefined' ||
    format == null ||
    typeof format === 'undefined'
  )
    return null;

  return dayjs.utc(utcTime).tz(timeZoneIdentifier).format(format);
};

/**
 * @description Returns a formatted string for a date/time based on the input parameters.
 * @param {string} time - Date and/or Time that should be formatted to match the input format.
 * @param {string} format - Optional: Format for the date/time to be returned as a string.  Defaults to: YYYY-MM-DD HH:mm
 * @returns {string}
 */
export const formatDateTime = (time, format = 'YYYY-MM-DD HH:mm') => {
  // No time value? Nothing to return.
  if (time == null || typeof time === 'undefined') return null;

  // No format? return time value as-is.
  if (format == null || typeof format === 'undefined') return time;

  return dayjs.utc(time).format(format);
};

/**
 * @description Returns a formatted string after time add for a date/time based on the input parameters.
 * @param {string} datetime - Date and/or Time that should be formatted to match the input format.
 * @param {string} key - what needs to be add to date object. Here Key value are minutes,hours,seconds.
 * @param {number} value - numbers value for adding hours, minutes, seconds.
 * @param {string} format - Optional: Format for the date/time to be returned as a string.  Defaults to: YYYY-MM-DD HH:mm:ss
 * @returns {string}
 */
export const addTimeToDate = (datetime, key, value, format = 'YYYY-MM-DD HH:mm:ss') => {
  // No time value? Nothing to return.
  if (datetime == null || typeof datetime === 'undefined') return null;

  // No format? return time value as-is.
  if (format == null || typeof format === 'undefined') return datetime;
  // in feature we can added more cases if needed.
  switch (key) {
    case 'm':
      return dayjs.utc(datetime).add(value, 'minutes').format(format);
    case 'h':
      return dayjs.utc(datetime).add(value, 'hours').format(format);
    case 'd':
      return dayjs.utc(datetime).add(value, 'days').format(format);
    default:
      return datetime;
  }
};

/**
 * @description Return datetime object in dayjs object.
 * @param {string} date - Datetime in string format.
 * @returns {any}
 */
export const getDatetimeUtc = (date) => {
  // validate input parameter
  if (date == null) {
    return null;
  }

  return dayjs.utc(date);
};

/**
 * @description Format a station local UTC offset as a string (e.g. +02:00, -07:00). Meant to compare the UTC and equivalent Station Local date/time.
 * @param {string} utcDateTime - UTC date/time string. Requires full date time. Format must match the other date/time parameter.
 * @param {string} stationDateTime - Station local date/time string equivalent to first parameter. Requires full date time. Format must match the other date/time parameter.
 * @param {string} parameterFormat - Optional: string format for the date/time parameters.  Defaults to: YYYY-MM-DD HH:mm.
 * @returns {string}
 */
export const formatUtcOffset = (utcDateTime, stationDateTime, parameterFormat = 'YYYY-MM-DD HH:mm') => {
  // validate input parameters.
  if (
    utcDateTime == null ||
    typeof utcDateTime === 'undefined' ||
    stationDateTime == null ||
    typeof stationDateTime === 'undefined' ||
    parameterFormat == null ||
    typeof parameterFormat === 'undefined'
  )
    return null;

  // Creating these objects using the same time zone and avoid any DST shenanigans.
  const timeA = dayjs.utc(utcDateTime, parameterFormat);
  const timeB = dayjs.utc(stationDateTime, parameterFormat);
  // calculate the difference in minutes
  const diff = timeB.diff(timeA, 'minute');
  // determine the hours and minutes as numbers.
  // Use Math.abs() first to make the number positive if negative, and then use Math.floor to make it a whole number.
  // !!!Note: using Math.floor on a negative number acts similar to rounding up on a positive number.
  const hours = Math.floor(Math.abs(diff / 60));
  const minutes = Math.floor(Math.abs(diff % 60));

  // Since terrestrial time zone differences will never be more than double-digits
  // this padding for hours and minutes should be safe.
  return diff < 0
    ? `-${('00' + hours).slice(-2)}:${('00' + minutes).slice(-2)}`
    : `+${('00' + hours).slice(-2)}:${('00' + minutes).slice(-2)}`;
};

/**
 * Converts the given duration in minutes into the following format:
 * xh xm (i.e 5h 36m)
 * @param {number} total_minutes
 * @returns string in the following format:
 * xh xm (i.e 5h 36m)
 */
export const getTimeHoursMinutes = (total_minutes) => {
  let hours = Math.floor(total_minutes / 60);
  let minutes = total_minutes % 60;
  return hours + ' hrs ' + minutes + ' mins';
};

/**
 * Returns the time difference, in specified unit of time, between startDateTime and endDateTime
 * @param {string} startDateTime - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @param {string} endDateTime - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @param {string} unit - unit of time (eg 'hour', 'minute', etc). Defaults to 'minute'
 * @param {boolean} returnFloat - specify if a float should be returned or not. Defaults to false
 * (i.e function returns an integer)
 * @requires unit must be a valid time unit
 * @returns {number}  difference (in specified unit) in number, i.e. "-1" , "2"
 */
export const getTimeDifference = (startDateTime, endDateTime, unit = 'minute', returnFloat = false) => {
  return startDateTime && endDateTime ? dayjs.utc(endDateTime).diff(dayjs.utc(startDateTime), unit, returnFloat) : 0;
};

/**
 * Returns the time difference, in Minutes, between startDateTime and endDateTime
 * @param {string} datetimestring - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @returns {string}  day of datetimestring i.e. mon, tue, wed, thu, fri, sat, sun
 */
export const getDayOfWeekDisplayString = (datetimeString) => {
  const display = dayjs.utc(datetimeString).format('ddd');
  return display;
};

/**
 * Returns the number of days gap between the zulu time and the local time
 * @param {string} dateTimestring - ISO date string, i.e. "2020-09-23T02:11:00Z"
 * @param {string} timeZone - timeZone of station, if null, compare to utc date time
 * @param {string} utcDateToCompareString - YYYY-MM-DD format
 * @returns {number}  number of days difference (i.e. 0, 1, -1)
 */
export const getDayDifference = (dateTimeString, stationTimeZone, utcDateToCompareString) => {
  if (utcDateToCompareString == null || dateTimeString == null) return null;

  let dateTime = null;
  // if station time zone is null, test against utc date, formatting to a string
  if (stationTimeZone == null || typeof stationTimeZone == 'undefined') {
    dateTime = dayjs.utc(dateTimeString).tz(TimeZones.UTC).format('YYYY-MM-DD');
  } else {
    dateTime = dayjs.utc(dateTimeString).tz(stationTimeZone).format('YYYY-MM-DD');
  }

  // format to a date string (this is because we're having trouble getting dayjs to do what we want)
  const utcDateToCompare = dayjs.utc(utcDateToCompareString).tz(TimeZones.UTC).format('YYYY-MM-DD');

  // get the number of days difference of the date strings
  const diff = dayjs(dateTime).diff(dayjs(utcDateToCompare), 'd');

  return diff;
};

export const getDatetimeUtcNowString = (format = 'YYYY-MM-DD HH:mm:ss') => {
  // validate input parameter
  if (format == null) {
    return null;
  }

  return dayjs.utc().format(format);
};

/**
 * @description Converts a given UTC offset into a numerical value (float)
 * (e.g. +08:30 => 8.5;  -05:45 => -5.75)
 * @param offset in the following format: ±HH:MM
 * @requires offset should be in the following format: ±HH:MM
 * @returns {number}
 */
export const getUTCOffsetValue = (date, timezone) => {
  var dateZ = dayjs(date).tz(timezone, true).format('Z');
  var offset = dateZ.slice(-7);

  var offsetString = offset.toString();
  var signChar = offsetString.slice(0, 1);
  var sign = signChar === '+' ? 1 : -1;

  var hour = offsetString.slice(1, 3);
  var minutes = offsetString.slice(4, 6);

  var hourValue = parseInt(hour);
  var minuteValue = parseInt(minutes) / 60.0;

  return sign * (hourValue + minuteValue);
};

/**
 * @description compares 2 times and determines if the matchTime is Before targetTime
 * @param {*} matchTime
 * @param {*} targetTime
 * @returns returns true if matchTime is Before targetTime
 */
export const datetimeIsBefore = (matchTime, targetTime) => {
  return matchTime && targetTime && dayjs.utc(matchTime).isBefore(dayjs.utc(targetTime));
};

/**
 * @description get date for provided datetime string
 * @param {string} dateString
 * @returns datetime string
 */
export const getDateForOperationalTz = (dateString) => {
  return dayjs(dateString).tz(TimeZones.PDT).format('YYYY-MM-DD');
};

/**
 * @description get start and end date for a UTC time in operational time zone
 * @param {string} dateString
 * @returns {object} {startDate, endDate}
 */
export const getUtcStartEndDateTimeForOperationalTz = (dateString) => {
  // I had to do this way I think because of the way dayjs handles timezones. If you chain it all together
  // It adds an extra hour.
  const startOfDay = dayjs.tz(dateString, TimeZones.UTC).tz(TimeZones.PDT).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  const endOfDay = dayjs.tz(dateString, TimeZones.UTC).tz(TimeZones.PDT).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
  const startDate = dayjs.tz(startOfDay, TimeZones.PDT).tz(TimeZones.UTC).format('YYYY-MM-DDTHH:mm:ss');
  const endDate = dayjs.tz(endOfDay, TimeZones.PDT).tz(TimeZones.UTC).format('YYYY-MM-DDTHH:mm:ss');
  return { startDate, endDate };
};

/**
 * @description Check if there is overlap between two time periods
 * @param {string} start1 - start time of first period
 * @param {string} end1 - end time of first period
 * @param {string} start2 - start time of second period
 * @param {string} end2 - end time of second period
 * @returns {boolean}
 */
export const checkOverlap = (start1, end1, start2, end2) => {
  const startTime1 = dayjs.tz(start1, TimeZones.UTC);
  const endTime1 = dayjs.tz(end1, TimeZones.UTC);
  const startTime2 = dayjs.tz(start2, TimeZones.UTC);
  const endTime2 = dayjs.tz(end2, TimeZones.UTC);

  return (
    (startTime1.isBefore(endTime2) && endTime1.isAfter(startTime2)) ||
    (startTime2.isBefore(endTime1) && endTime2.isAfter(startTime1))
  );
};

/**
 * @description Returns the date for yesterday or tomorrow based on the input parameter.
 * @param {string} day - 'yesterday' or 'tomorrow'
 * @param {string} format - Optional: Format for the date to be returned as a string. Defaults to: 'YYYY-MM-DD'
 * @returns {string}
 */
export const getRelativeDate = (day, format = 'YYYY-MM-DD') => {
  const now = dayjs().tz(TimeZones.PDT);
  if (day === 'yesterday') {
    return now.subtract(1, 'day').format(format);
  } else if (day === 'tomorrow') {
    return now.add(1, 'day').format(format);
  } else if (day === 'today') {
    return now.format(format);
  } else {
    throw new Error("Invalid input: day must be 'yesterday' or 'tomorrow'");
  }
};
