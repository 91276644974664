import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FlightInfoTable from '../FlightInfoTable/FlightInfoTable';
import { Grid } from '@material-ui/core';

const FlightInfoTables = memo(
  ({ flightInfoData = [], columnNames = [], currentFlight = { deadHeadCrewTrips: [] }, isDeadheadsExpanded }) => {
    const [tripInfo, setTripInfo] = useState([]);
    const [deadheadTripsInfo, setDeadheadTripsInfo] = useState([]);

    const currentFlightDeadheads = useMemo(() => {
      return currentFlight?.deadHeadCrewTrips?.map((deadhead) => deadhead.tripNumber) ?? [];
    }, [currentFlight]);

    useEffect(() => {
      if (flightInfoData.length > 0) {
        const deadheadTrips = flightInfoData.filter((flight) => currentFlightDeadheads.includes(flight.tripNumber));
        const nonDeadheadTrips = flightInfoData.filter((flight) => !currentFlightDeadheads.includes(flight.tripNumber));
        setTripInfo(nonDeadheadTrips);
        setDeadheadTripsInfo(deadheadTrips);
      }
    }, [flightInfoData]);

    const hasDeadheadsData = deadheadTripsInfo.length > 0;

    return (
      <div className="flight-info-tables">
        {!isDeadheadsExpanded ? (
          <Grid container spacing={2} style={{ padding: '1rem' }}>
            {tripInfo.length == 0 && <div>Failed to retrieve Crew data. Please try again.</div>}
            {tripInfo.length > 0 &&
              Array.from(tripInfo).map((flightInfo, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                  <FlightInfoTable
                    flightInfo={flightInfo.tripFlightDetails}
                    trip={flightInfo.tripNumber}
                    date={flightInfo.tripDate}
                    columnNames={columnNames}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Grid container spacing={2} style={{ padding: '1rem' }}>
            {hasDeadheadsData &&
              Array.from(deadheadTripsInfo).map((flightInfo, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                  <FlightInfoTable
                    flightInfo={flightInfo.tripFlightDetails}
                    trip={flightInfo.tripNumber}
                    date={flightInfo.tripDate}
                    columnNames={columnNames}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </div>
    );
  },
);
FlightInfoTables.propTypes = {
  columnNames: PropTypes.array,
  flightInfoData: PropTypes.array,
  currentFlight: PropTypes.object,
  isDeadheadsExpanded: PropTypes.bool,
};
export default FlightInfoTables;
