import { axiosInstance } from '../apiClient';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../../lib/appInsightsUtils';

const API_ROUTE = 'flight';
const moduleName = 'flightsApi';

export const getFlights = async (startDateUtc, endDateUtc) => {
  const apiClient = axiosInstance;
  const endDateParam = !endDateUtc ? '' : `&endDateUTC=${endDateUtc}`;
  const url = `${API_ROUTE}/api/v2/flights?startDateUTC=${startDateUtc}${endDateParam}`;
  return await apiClient.get(url);
};

export const getFlightsFiltered = async (
  startDateUtc,
  endDateUtc,
  aircrafts = [],
  flightNumbers = [],
  origins = [],
  destinations = [],
) => {
  const apiClient = axiosInstance;

  const endDateParam = !endDateUtc ? '' : `&endDateUTC=${endDateUtc}`;
  var constructUrlParamsArray = (arrayValues, apiParamsName) => {
    let urlParamsStr = '';
    if (arrayValues != null && Array.isArray(arrayValues)) {
      arrayValues.forEach((value, index) => {
        urlParamsStr += `&${apiParamsName}=${value}`;
      });
    }
    return urlParamsStr;
  };
  let result = null;
  try {
    let aircraftsParam = constructUrlParamsArray(aircrafts, 'aircrafts');

    let flightNumbersParam = constructUrlParamsArray(flightNumbers, 'flights');
    let originParam = constructUrlParamsArray(origins, 'origins');

    let destinationParam = constructUrlParamsArray(destinations, 'destination');

    const url = `${API_ROUTE}/api/v2/flights?startDateUTC=${startDateUtc}${endDateParam}${aircraftsParam}${flightNumbersParam}${originParam}${destinationParam}`;
    // const url = `https://localhost:62189/api/v2/flights?startDateUTC=${startDateUtc}${endDateParam}${aircraftsParam}${flightNumbersParam}${originParam}${destinationParam}`;

    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getFlightsFiltered', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

export const getFlightsV3 = async (startDateTimeUTC, endDateTimeUTC, airlines) => {
  const apiClient = axiosInstance;
  const endDateParam = !endDateTimeUTC ? '' : `&endDateTimeUTC=${endDateTimeUTC}`;
  const airlinesParam = airlines?.length
    ? airlines.map((airline) => `&airlines=${encodeURIComponent(airline)}`).join('')
    : '';
  const url = `${API_ROUTE}/api/v3/flights?startDateTimeUTC=${startDateTimeUTC}${endDateParam}${airlinesParam}`;
  return await apiClient.get(url);
};

/** Returns a list of flight legs that were found matching the expected swapped aircraft registration */
export const findFlightLegs = async (payload) => {
  let result = null;
  const apiClient = axiosInstance;
  try {
    const url = `${API_ROUTE}/api/v1/flights/find`;
    const response = await apiClient.post(url, payload);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'findFlightLegs', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * Returns a next flight leg
 * @param {*} flightNumber
 * @param {*} departureDateUTC
 * @param {*} origin
 * @param {*} destination
 * @param {*} departureCount
 * @param {*} airlineCode
 */

export const getNextFlightLeg = async (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  airlineCode,
) => {
  const apiClient = axiosInstance;
  let result = null;

  try {
    const url = `${API_ROUTE}/api/v1/flights/${flightNumber}/next?departureDateUTC=${departureDateUTC}&origin=${origin}&destination=${destination}&departureCount=${departureCount}&airlineCode=${airlineCode}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getNextFlightLeg', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  //console.debug(result);
  return result;
};

/**
 * Returns flight details for a flight leg
 * @param {*} flightNumber
 * @param {*} departureDateUTC
 * @param {*} origin
 * @param {*} destination
 * @param {*} departureCount
 * @param {*} airlineCode
 */

export const getFlightDetails = async (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  airlineCode,
) => {
  const apiClient = axiosInstance;
  let result = null;

  if (flightNumber === undefined) {
    return null;
  }
  try {
    const url = `${API_ROUTE}/api/v1/flights/${flightNumber}?departureDateUTC=${departureDateUTC}&origin=${origin}&destination=${destination}&departureCount=${departureCount}&airlineCode=${airlineCode}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getFlightDetails', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw error;
  }
  return result;
};

/**
 * Returns flight details for a flight leg using v4 endpoint
 * @param {*} flightNumber
 * @param {*} departureDateUTC
 * @param {*} origin (optional)
 * @param {*} destination (optional)
 * @param {*} departureCount (optional)
 * @param {*} airlineCode
 */
export const getFlightDetailsV4 = async (
  flightNumber,
  departureDateUTC,
  origin = null,
  destination = null,
  departureCount = null,
  airlineCode,
) => {
  const apiClient = axiosInstance;
  let result = null;

  if (!flightNumber || !departureDateUTC || !airlineCode) {
    return null;
  }

  try {
    let url = `${API_ROUTE}/api/v4/flights/${flightNumber}?departureDateUTC=${departureDateUTC}&airlineCode=${airlineCode}`;
    if (origin) url += `&origin=${origin}`;
    if (destination) url += `&destination=${destination}`;
    if (departureCount) url += `&departureCount=${departureCount}`;

    const response = await apiClient.get(url);

    result = response.data.length > 0 ? response.data[0] : response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'getFlightDetailsV4', createApiErrorTelemetryProperties(error));
    console.error(error);
    throw error;
  }
  return result;
};

/**
 * Returns if a flight leg exists or not found
 * @param {*} flightNumber
 * @param {*} departureDateUTC
 * @param {*} origin
 * @param {*} destination
 * @param {*} departureCount
 * @param {*} airlineCode
 * @param {*} irropsCode
 * @param {*} aircraftRegistration
 * @param {*} queryTimeout
 */
export const headCheckFlightExists = async (
  flightNumber,
  departureDateUTC,
  origin,
  destination,
  departureCount,
  airlineCode,
  irropsCode,
  aircraftRegistration,
  queryTimeout,
) => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    let url = `${API_ROUTE}/api/v1/flights/${flightNumber}?departureDateUTC=${departureDateUTC}&airlineCode=${airlineCode}`;
    if (origin) {
      url = url + `&origin=${origin}`;
    }
    if (destination) {
      url = url + `&destination=${destination}`;
    }
    if (departureCount) {
      url = url + `&departureCount=${departureCount}`;
    }
    if (queryTimeout == false) {
      url = url + `&queryTimeout=${queryTimeout}`;
    }
    if (irropsCode != null) {
      url = url + `&irropsCode=${irropsCode}`;
    }
    if (aircraftRegistration) {
      url = url + `&aircraftRegistration=${aircraftRegistration}`;
    }
    result = await apiClient.head(url);
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'headCheckFlightExists', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * @param {*} flightDetails
 * @param {*} config
 * @returns
 */
export const checkFlightExists = async (flightDetails, config = null) => {
  const {
    flightNumber,
    departureDateUTC,
    origin,
    destination,
    departureCount,
    airlineCode,
    irropsCode,
    aircraftRegistration,
    queryTimeout,
  } = flightDetails;
  const apiClient = axiosInstance;

  let url = `${API_ROUTE}/api/v1/flights/${flightNumber}?departureDateUTC=${departureDateUTC}&airlineCode=${airlineCode}`;
  if (origin) {
    url = url + `&origin=${origin}`;
  }
  if (destination) {
    url = url + `&destination=${destination}`;
  }
  if (departureCount) {
    url = url + `&departureCount=${departureCount}`;
  }
  if (queryTimeout === false) {
    url = url + `&queryTimeout=${queryTimeout}`;
  }
  if (irropsCode != null) {
    url = url + `&irropsCode=${irropsCode}`;
  }
  if (aircraftRegistration) {
    url = url + `&aircraftRegistration=${aircraftRegistration}`;
  }
  return config ? await apiClient.head(url, config) : await apiClient.head(url);
};

/**
 * Returns if a flight leg exists or not found
 * @param {*} flightNumber
 * @param {*} estimatedTimeOutUTC
 * @param {*} airlineCode
 * @param {*} aircraftRegistration
 */
export const getScheduledOperatingDateUTC = async (
  flightNumber,
  estimatedTimeOutUTC,
  airlineCode,
  aircraftRegistration,
) => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    let url = `${API_ROUTE}/api/v1/flights/${flightNumber}/scheduledOperatingDateUTC?estimatedTimeOutUTC=${estimatedTimeOutUTC}&aircraftRegistration=${aircraftRegistration}&airlineCode=${airlineCode}`;
    result = await apiClient.get(url);
  } catch (error) {
    // Log the error message from the API.
    logError(
      getApiErrorMessage(error),
      moduleName,
      'scheduledOperatingDateUTC',
      createApiErrorTelemetryProperties(error),
    );
    console.error(error);
  }
  return result?.data;
};

/**
 * Returns given flight number is available or not.
 * @param {*} flightNumber
 * @param {*} startDateUTC
 * @param {*} origin
 */

export const getIsFlightAvailable = async (flightNumber, startDateUTC, origin) => {
  const apiClient = axiosInstance;
  let result = null;
  try {
    const url = `${API_ROUTE}/api/v1/flights/${flightNumber}/available?startDateUTC=${startDateUTC}&origin=${origin}`;
    const response = await apiClient.get(url);
    result = response.data;
  } catch (error) {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'getIsFlightAvailable', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};

/**
 * update Irrops reason and comments
 * @param {*} flightsData
 */

export const updateIrropsReason = async (flightsData) => {
  const apiClient = axiosInstance;
  let result = null;

  const flightLegPutRequest = {
    irregularOperationsReason: flightsData.selectedReason,
    flightLegComment: flightsData.comment,
    modifiedDate: flightsData.modifiedDate,
    modifiedBy: flightsData.userName,
  };

  try {
    const url = `${API_ROUTE}/api/v1/flights/${flightsData.flightNumber}?departureDateUTC=${flightsData.departureDateUTC}&origin=${flightsData.origin}&destination=${flightsData.destination}&departureCount=${flightsData.departureCount}&airlineCode=${flightsData.airlineCode}`;
    const response = await apiClient.put(url, flightLegPutRequest);
    result = response.data;
  } catch (err) {
    // Log the error message from the API.
    logError(getApiErrorMessage(err), moduleName, 'updateIrropsReason', createApiErrorTelemetryProperties(err));
    console.error(err);
  }

  return result;
};
