import Transformers from '../../lib/transformer/Transformers';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';
import { AxiosError } from 'axios';

/**
 * Intercept the api call and alter the response based on the transformer in the given settings.
 *
 * Mock API definition example:
 *    {
 *      "uri": "airtrakmanager/api/v1/Gantt/filter",
 *      "transform":
 *      {
 *        "response":
 *            {
 *              "status": 200,
 *              "data":
 *              [
 *                {
 *                  "lineOfFlights.N625AS.scheduled[1].flightPuckData.hasMaintenanceMessages": "true"
 *                },
 *                {
 *                  "lineOfFlights.N626AS.scheduled[1].flightPuckData.hasMaintenanceMessages": "true"
 *                }
 *              ]
 *            }
 *		  }
 *    }
 * @returns {Object} intercept for mocking an api
 */
const mockApiInterceptor = () => {
  const { getTransformer, transform } = Transformers();

  const mockResponse = (response) => {
    const url = response?.config?.url;
    if (!url) {
      return response;
    }
    const adoMockApi = getSessionStorageItem('ADO_MOCK_API');
    const transformer = getTransformer(adoMockApi, () => adoMockApi?.uri === url);
    if (!transformer) {
      return response;
    }
    const data = transform(response?.data, transformer?.data);

    response.data = data;
    response.status = transformer.status || response.status;
    if (response.status !== 200) {
      throw AxiosError(response);
    }
    return response;
  };

  const mockError = (error) => {
    const url = error?.config?.url;
    if (!url) {
      throw error;
    }
    const adoMockApi = getSessionStorageItem('ADO_MOCK_API');
    const transformer = getTransformer(adoMockApi, () => adoMockApi?.uri === url);
    if (!transformer) {
      throw error;
    }
    const data = transform(error.response.data, transformer?.data);

    error.response.data = data;
    error.response.status = transformer.status || error.response.status;
    if (error.response.status !== 200) {
      throw error;
    }
    return error.response;
  };

  return { mockResponse, mockError };
};

export default mockApiInterceptor;
