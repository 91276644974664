import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toolbar from './Toolbar/Toolbar';
import { ViewWindowType, ResizeType, ViewWindowTitle } from '../../../../../lib/constants';
import useDraggableViewWindow from '../../../../../hooks/useDraggableViewWindow/useDraggableViewWindow';
import useResizableViewWindow from '../../../../../hooks/useResizableViewWindow/useResizableViewWindow';
import { useWindowManagerDispatch } from '../../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import { calculateMinimizedPosition, dragEndCallback, resizeEndCallback, getWindowStyles } from './utils';
import './ViewWindow.css';
import useDefaultRoleFilters from '../../../../../hooks/useDefaultRoleFilters/useDefaultRoleFilters';
import { useFilterStore } from '../../../../../hooks/useFilterStore/useFilterStore';
import { getDefaultFilter, getTitleFromSearchFilter } from '../../../../../lib/utils';
import '../../../../../styles/ganttScale.css';
import { getGanttScaleCssClassName } from '../../../GanttChart/ganttHelpers';
import { useUserPreferencesData } from '../../../../../contexts/UserPreferencesContext/UserPreferencesContext';
import { useViewConfigurationStore } from '../../../../../hooks/useViewConfigurationStore/useViewConfigurationStore';

const ViewWindow = ({
  variant,
  title,
  isFocused = true,
  onFocusToWindow = () => {},
  children,
  onClose,
  windowPosition,
  id,
  isMinimized,
  minimizedIndex,
  isMaximized,
  currentActiveId,
  isTailIndicatorsEnabled,
  isDefaultTitle,
  searchFilterTitle,
  setSearchFilterTitle,
  ...viewData
}) => {
  useDefaultRoleFilters();

  const viewConfigurationData = useViewConfigurationStore();

  const ref = useRef(null);
  const {
    setMinimizedView,
    removeMinimizedView,
    setMaximizedView,
    removeMaximizedView,
    setDraggedMiniView,
    updateViewWindow,
  } = useWindowManagerDispatch();

  const [initialMinimizedPos, setInitialMinimizedPos] = useState({ x: 0, y: 0 });
  const { filter, isDefaultFilter, defaultAssignmentFilter } = useFilterStore();
  const { state: userPreferencesState } = useUserPreferencesData();
  const defaultFilter = useMemo(() => getDefaultFilter(defaultAssignmentFilter), [defaultAssignmentFilter]);

  useEffect(() => {
    if (setSearchFilterTitle) {
      if (title?.includes(ViewWindowTitle.DEFAULT) && !isDefaultFilter) {
        setSearchFilterTitle(getTitleFromSearchFilter(filter, defaultFilter));
      } else {
        setSearchFilterTitle(null);
      }
    }
  }, [isDefaultFilter, filter, title]);

  const canDragWindow = useMemo(() => {
    return variant === ViewWindowType.MULTI;
  }, [variant]);

  const canResizeWindow = useMemo(() => {
    return variant === ViewWindowType.MULTI && !isMinimized;
  }, [variant, isMinimized]);

  const draggableRef = useDraggableViewWindow({
    dragHandleElementId: 'toolbar-drag-area',
    canDrag: canDragWindow,
    dragEndCallback: (refCurrent) => {
      dragEndCallback(
        refCurrent,
        id,
        setDraggedMiniView,
        initialMinimizedPos,
        isMinimized,
        viewData.viewPosition,
        updateViewWindow,
      );
    },
  });

  const resizableViewWindowParams = {
    containerRef: ref,
    canResize: canResizeWindow,
    resizeEndCallback: (refCurrent) => {
      resizeEndCallback(refCurrent, id, updateViewWindow);
    },
  };

  const resizeTopHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP,
    ...resizableViewWindowParams,
  });
  const resizeRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.RIGHT,
    ...resizableViewWindowParams,
  });
  const resizeBottomHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM,
    ...resizableViewWindowParams,
  });
  const resizeLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.LEFT,
    ...resizableViewWindowParams,
  });
  const resizeBottomRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM_RIGHT,
    ...resizableViewWindowParams,
  });
  const resizeBottomLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM_LEFT,
    ...resizableViewWindowParams,
  });
  const resizeTopLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP_LEFT,
    ...resizableViewWindowParams,
  });
  const resizeTopRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP_RIGHT,
    ...resizableViewWindowParams,
  });

  const { className, style } = useMemo(
    () =>
      getWindowStyles({
        variant,
        isMaximized,
        isMinimized,
        minimizedIndex,
        calculateMinimizedPosition,
        initialMinimizedPos,
        setInitialMinimizedPos,
        viewData,
        windowPosition,
      }),
    [viewData, variant, isMaximized, isMinimized, minimizedIndex, windowPosition],
  );
  const attachRefs = useCallback(
    (el) => {
      ref.current = el;
      draggableRef.current = el;
    },
    [draggableRef],
  );

  const handleMaximize = useCallback(() => {
    if (isMaximized) {
      removeMaximizedView(id);
    } else {
      setMaximizedView(id);
    }
    removeMinimizedView(id);
  }, [isMaximized, id, removeMaximizedView, setMaximizedView]);

  const handleMinimize = useCallback(() => {
    if (!isMinimized) {
      removeMaximizedView(id);
      setMinimizedView(id);
    }
  }, [isMinimized, setMinimizedView, id, removeMaximizedView, minimizedIndex]);

  const handleCloseClick = () => {
    onClose(id);
  };

  const handleFocusToWindow = () => {
    if (currentActiveId === id) return;
    onFocusToWindow(id);
  };

  /**
   * Renders the toolbar component based on the current view window type.
   * @returns {JSX.Element|null} The Toolbar component if the view window type is not SWAP_MODE, otherwise null.
   */
  const renderToolbar = useMemo(() => {
    if (variant === ViewWindowType.SWAP_MODE) return null;
    return (
      <Toolbar
        title={searchFilterTitle || title}
        variant={variant}
        onMaximize={handleMaximize}
        onMinimize={handleMinimize}
        onClose={handleCloseClick}
        isMinimized={isMinimized}
        currentActiveId={currentActiveId}
      />
    );
  }, [variant, handleMaximize, handleMinimize, handleCloseClick, isMinimized, currentActiveId]);

  return (
    <div
      data-cy="view-window"
      className={`view-window${isFocused ? ' focused' : ''}${className}${
        isMaximized ? ' maximized' : ''
      } ${getGanttScaleCssClassName(viewConfigurationData.puckSize ?? userPreferencesState.puckSize.puckSize)} ${
        isTailIndicatorsEnabled ? 'tail-indicators-enabled' : ''
      } view-window-${id}`}
      id={`view-window-${id}`}
      style={style}
      ref={attachRefs}
      onMouseDown={handleFocusToWindow}
    >
      {renderToolbar}
      {<div className="view-window-content">{children}</div>}
      {canResizeWindow && (
        <div>
          <div className="resize-corner" id="resize-bottom-right" ref={resizeBottomRightHandleRef} />
          <div className="resize-corner" id="resize-bottom-left" ref={resizeBottomLeftHandleRef} />
          <div className="resize-corner" id="resize-top-right" ref={resizeTopRightHandleRef} />
          <div className="resize-corner" id="resize-top-left" ref={resizeTopLeftHandleRef} />
          <div className="resize-side" id="resize-right" ref={resizeRightHandleRef} />
          <div className="resize-side" id="resize-left" ref={resizeLeftHandleRef} />
          <div className="resize-side" id="resize-bottom" ref={resizeBottomHandleRef} />
          <div className="resize-side" id="resize-top" ref={resizeTopHandleRef} />
        </div>
      )}
    </div>
  );
};

ViewWindow.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocusToWindow: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  windowPosition: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isMinimized: PropTypes.bool,
  minimizedIndex: PropTypes.number,
  isMaximized: PropTypes.bool,
  isSwapModeActive: PropTypes.bool,
  currentActiveId: PropTypes.number.isRequired,
  isTailIndicatorsEnabled: PropTypes.bool,
  searchFilterTitle: PropTypes.object,
  setSearchFilterTitle: PropTypes.func,
};

export default ViewWindow;
